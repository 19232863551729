import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './SearchTreatmentCard.css';

class SearchTreatmentCard extends React.Component {
    render() {

        return (

            <div className="search_treatmentCard">
                <div className="line"></div>
                <div className="card">
                    <div className="search_imgCardTreatment" >
                        <img src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/' + this.props.treatment.clinic.id + '.jpg'} />
                        <div></div></div>
                    <div className="search_resultInfoHolderT">

                        <h4> <span> {this.props.treatment.clinic.type}:</span><br></br><a href={process.env.PUBLIC_URL + "/clinic/" + this.props.treatment.clinic.id}> {this.props.treatment.clinic.name}</a></h4>
                        <span className="treatmentName">{this.props.treatment.name} </span>
                        <span className="treatmentPrice">{this.props.treatment.price}€ </span>
                    </div>
                    <button onClick={() => this.props.handleBookingClick("threatman", this.props.treatment)}>Book a visit</button>
                </div>
            </div>
        );

    }
}

export default SearchTreatmentCard;