import React from 'react';
import ReactDOM from 'react-dom';


import './AboutClinic.css';
import eng from './english_icon.png';
import ger from './german_icon.png';
import img_info_score from './score_icon.png'

class AboutClinic extends React.Component {
    
    render() {
        if (this.props.clinic == null || this.props.clinic.length == 0) {
            return (

                <div >
                    <h4>Sorry we could not find any clinic matching your request.</h4>
                </div>
            );
        } else {

            return (
                <div className="aboutClinic">

                    <img className="aboutClinicImage" src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/' + this.props.clinic.id + '.jpg'} />
                    <div className="aboutClinicDescription">
                        <div className="clinicDescription">
                            {this.props.clinic.description}
                        </div>
                        <div className="clinic_scoreLangHolder">
                            <img src={ger} />
                            <img src={img_info_score} />
                        </div>

                        <div className="clinicLanguage"><span>Language/s spoken: </span><img src={eng} /> <img src={ger} /></div>
                        <button onClick={() => this.props.handleBookingClick("clinic", this.props.clinic)}>Book a visit</button>
                    </div>

                </div>
            );
        }
    }
}

export default AboutClinic;