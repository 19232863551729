import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './footer.css';
import img_logo from "./logo.png";
import plavi_telefon from "./plavi_telefon.png";
import lokacija_plava from "./lokacija_plava.png";
import whatsapp from "./whatsapp.png";
import twitter from "./twitter.png";
import facebook_icon from "./facebook_icon.png";
import instagram from "./instagram.png";


class Footer extends React.Component {
    constructor(props) {

      super();
    }

    render() {

        return (
            <div>
                <div className='footerHolder'>
                    <div className='footerBannerHolder'>
                        Make your Health easier than ever!
                </div>
                    <table className='footerContentHolder'>
                        <tr>
                            <td className='footerContentHolder1'>
                                <h2><span className='healthSpan'>HEALTH</span><span className='scannerSpan'>SCANNER</span></h2>
                                <span className='healthScannerTextSpan'>We aim at bringing the revolution to healthcare. Our platform allows users to book the best medical
                                professionals across the world, exploit wellness and spa treatments and experience telemedicine like never before!
                                </span>
                                <br />
                                <img src={facebook_icon} /><img src={whatsapp} /><img src={twitter} /><img src={instagram} />
                            </td>
                            <td className='footerContentHolder2'>
                                <h3>Legal</h3>
                                <span>FAQs</span><br />
                                <span>Privacy Policy</span><br />
                                <span>Terms of Use</span><br />
                                <span>Patient Identity Verification</span><br /><br />
                                <h3>Our site</h3>
                                <span>Home</span><br />
                                <span>About Us</span><br />
                            </td>
                            <td className='footerContentHolder3'>
                                <h3>Contact</h3>
                                <br />
                                <span><img className="icons" src={plavi_telefon} />&nbsp;&nbsp;&nbsp;&nbsp;+5484615648</span><br /><br />
                                <span><img className="icons" src={lokacija_plava} />&nbsp;&nbsp;&nbsp;&nbsp;81667, Munich, Germany</span>
                                <br />
                                <br />
                                <br />
                                <img src={img_logo} />
                            </td>
                        </tr>
                    </table>
                    <div className='footerCopyrightHolder'>
                        HealthScanner © 2020.
                    </div>
                </div>

                <div className='footerHolderMob'>
                    <div className='footerBannerHolderMob'>
                        Make your Health easier than ever!
                </div>
                    <table className='footerContentHolderMob'>
                        <tr>
                            <td className='footerContentHolder1Mob'>
                                <h3>Legal</h3>
                                <span>FAQs</span><br />
                                <span>Privacy Policy</span><br />
                                <span>Terms of Use</span><br />
                                <span>Patient Identity Verification</span><br /><br />
                            </td>
                            <td className='footerContentHolder2Mob'>
                                <h3>Contact</h3>
                                <br />
                                <span><img className="iconsMob" src={plavi_telefon} />&nbsp;&nbsp;+5484615648</span><br /><br />
                                <span><img className="iconsMob" src={lokacija_plava} />&nbsp;&nbsp;81667, Munich, Germany</span>
                            </td>
                        </tr>
                    </table>
                    <div className='footerLogoHolderMob'>
                        <img src={img_logo} />
                    </div>
                    <div className='footerSocialtHolderMob'>
                        <img src={facebook_icon} /><img src={whatsapp} /><img src={twitter} /><img src={instagram} />
                    </div>
                    <div className='footerCopyrightHolderMob'>
                        HealthScanner © 2022.
                    </div>
                </div>
            </div>

        );

    }

}
export default Footer;
