import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './SearchResultClinic.css';

import SearchClinicCard from '../SearchClinicCard/SearchClinicCard'

class SearchResultClinic extends React.Component {
    render() {

        if (this.props.clinicList == null || this.props.clinicList.length == 0) {
            return (

                <div className="result_clinic result_hide">
                    <h4 className='h4_notFound'>Sorry we could not find any clinics matching your request.</h4>
                </div>
            );
        } else {
            return (

                <div className="result_clinic result_hide">

                    {this.props.clinicList.map((value, i) => {
                        console.log("OVDE VALUE MAPA")
                        console.log(value);
                        return (
                            <SearchClinicCard
                                key={"scc_" + i}
                                clinic={value}
                                handleBookingClick={this.props.handleBookingClick}

                            />
                        )
                    })}


                </div>
            );
        }

    }
}

export default SearchResultClinic;