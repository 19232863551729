import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import $ from 'jquery';

//Components import
import Navbar from './components/Navbar/Navbar';
import HomeInfoBoard from './components/HomeInfoBoard/HomeInfoBoard';
import TitleSeparator from './components/TitleSeparator/TitleSeparator';
import Search from './components/Search/Search';
import NeedToKnow from './components/NeedToKnow/NeedToKnow';
import MedicalTreatments from './components/MedicalTreatments/MedicalTreatments';
import OurBest from './components/OurBest/OurBest';
import Footer from './components/Footer/Footer';
import SignInForm from './components/SignInForm/SignInForm';
//Pages import
import Home from './pages/Home/Home';
import SearchPage from './pages/SearchPage/SearchPage';
import ClinicPage from './pages/ClinicPage/ClinicPage';
import UserPage from './pages/UserPage/UserPage';
import UserClinic from './pages/UserClinic/UserClinic';
import DoctorPage from './pages/DoctorPage/DoctorPage';


class App extends React.Component {
    constructor() {
        super();
        console.log(JSON.parse(localStorage.getItem('bookings')))
        this.state = {
            bookings: JSON.parse(localStorage.getItem('bookings')) || [
                {
                    clinicID: 1000000,
                    clinicName: "DemoClinic",
                    clinicAddress: "Mali mokri lug 200",
                    doctorID: 2000000,
                    doctorName: "DemoDoctor",
                    treatmantValue: "Demo Tretmant 200 eura",
                    date: "28.05.2020",
                    time: "09:30-10:00"
                }
            ],
            user: JSON.parse(localStorage.getItem('user')) || {
                username: null,
                password: null,
                logedin: false,
                token: null,
                expiration:null,
                type:null,
            }
        }
        this.addNewBooking.bind(this);
        this.cancelBooking.bind(this);
    }
    componentDidMount() {
        if (Date.parse(this.state.user.expiration) - Date.now() <= 0) {
            this.setState({
                user: {
                    username: null,
                    password: null,
                    logedin: false,
                    token: null,
                    expiration: null,
                    type: null,
                }})
        };
    }
    addNewBooking =(
        clinicID,
        clinicName,
        clinicAddress,
        doctorID,
        doctorName,
        treatmantValue,
        date,
        time
    ) => {
        console.log("AWWW");
        console.log(this.state.bookings);
        let new_bookings = this.state.bookings.slice();
        new_bookings.push({
            clinicID: clinicID,
            clinicName: clinicName,
            clinicAddress: clinicAddress,
            doctorID: doctorID,
            doctorName: doctorName,
            treatmantValue: treatmantValue,
            date: date,
            time: time

        })
        this.setState({ bookings: new_bookings }, () => {
            localStorage.setItem('bookings', JSON.stringify(this.state.bookings));
        });

       
    }
    cancelBooking(index) {
        let new_bookings = this.state.bookings.slice();
        new_bookings.splice(index, 1);
        this.setState({ bookings: new_bookings },() => {
            localStorage.setItem('bookings', JSON.stringify(this.state.bookings));
        });
    }
    loginUser(username, password) {
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: username,
                password: password
            })
        };
        fetch('/api/User/Login', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("loginInformation", data)
                if (data.status === 401) {
                    $("#singin_msg").css({ "color": "red" });
                    $("#singin_msg").html("Sing in information you have provieded are not valid.");
                } else {
                    this.setState({
                        user: {
                            username: username,
                            password: password,
                            logedin: true,
                            token: data.token,
                            expiration: data.expiration,
                            name: data.user.name,
                            type: data.user.type
                        }
                    });
                   
                  
                    localStorage.setItem('user', JSON.stringify(this.state.user));
                    $("#signOutBtn").show();
                    $("#signInBtn").hide();
                    $(".signInFormHolder").hide();
                    $(".signInFormHolder_darker").hide();
                }
            })
    }
    logOutUser() {
        this.setState({
            user: {
                username: null,
                password: null,
                logedin: false,
                token: null,
                expiration: null,
                type: null,
            }
        }, () => {
                localStorage.setItem('user', JSON.stringify(this.state.user));       
        })
       
        $("#signOutBtn").hide();
        $("#signInBtn").show();

    }
    render() {
        return (
            <Router>
                <Navbar
                    logOutUser={this.logOutUser.bind(this)}
                    userLogedIn={this.state.user.logedin}
                    userInfo={this.state.user}
                ></Navbar>
                <SignInForm
                    loginUser={this.loginUser.bind(this)}
                   
                />
                <Switch>
                    <Route path="/doctor/:id" render={(props) =>
                        <DoctorPage
                            {...props}
                            
                            userInfo={this.state.user}
                            addNewBooking={this.addNewBooking.bind(this)}
                        />

                    } />
                    <Route path="/search/:val/:loc/:date" render={(props) =>
                        <SearchPage
                            {...props}
                            addNewBooking={this.addNewBooking.bind(this)}
                            userInfo={this.state.user}
                        />

                    } />
                    <Route path="/search/:val/:loc" render={(props) =>
                        <SearchPage {...props}
                            addNewBooking={this.addNewBooking.bind(this)}
                            userInfo={this.state.user}
                        />

                    } />
                    <Route path="/search/:val" render={(props) =>
                        <SearchPage {...props}
                            addNewBooking={this.addNewBooking.bind(this)}
                            userInfo={this.state.user}
                        />

                    } />
                    <Route path="/search" component={() =>
                        <SearchPage 
                            addNewBooking={this.addNewBooking.bind(this)}
                            userInfo={this.state.user}
                        />

                    }/>
                   
                    <Route path="/user" component={()=>
                        <UserPage
                            bookings={this.state.bookings}
                            cancelBooking={this.cancelBooking.bind(this)}
                            userInfo={this.state.user}
                            userLogedIn={this.state.user.logedin}
                        />

                    } />
                    <Route path="/userClinic" component={() =>
                        <UserClinic
                            bookings={this.state.bookings}
                            cancelBooking={this.cancelBooking.bind(this)}
                            userInfo={this.state.user}
                            userLogedIn={this.state.user.logedin}
                        />

                    } />
                    <Route path="/clinic/:id" component={(props) => 
                        <ClinicPage {...props}
                            addNewBooking={this.addNewBooking.bind(this)}
                            userInfo={this.state.user}
                        />
                    
                    } />
                    <Route path="/doctor" >
                        Doctor
                </Route>
                    <Route path="/login">
                        Login
                </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>

                <Footer/>
            </Router>
        );
    }
}

export default App;
