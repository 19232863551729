import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

import HomeInfoBoard from '../../components/HomeInfoBoard/HomeInfoBoard';
import TitleSeparator from '../../components/TitleSeparator/TitleSeparator';
import Search from '../../components/Search/Search';
import NeedToKnow from '../../components/NeedToKnow/NeedToKnow';
import MedicalTreatments from '../../components/MedicalTreatments/MedicalTreatments';
import OurBest from '../../components/OurBest/OurBest';
import './UserClinic.css';
import { Calendar, Whisper, Popover, Badge } from 'rsuite'; 
import { withRouter } from 'react-router';
import { render } from '@testing-library/react';

import { MessageUserBox } from '../../components/MessageUserBox/MessageUserBox'

//import axios from 'axios';

import doctor1 from './doccli1.png'
import billingpiecharts from './billingpiecharts.png'
import yeswork from './yeswork.png'
import nowork from './nowork.png'
import docimg1 from './2000000.png'
import docimg2 from './2000001.png'
import customerbilling from './customerbilling.png';

import client_1 from './client_1.jpg';
import client_2 from './client_2.jpg';
import client_3 from './client_3.jpg';

class UserClinic extends React.Component {

   
   
    constructor() {
        super();
        this.goToClinicPage.bind(this);
        this.cancelBooking.bind(this);
        this.saveFile.bind(this);
        this.uploadFile.bind(this);
        this.state = {
            cancelIndex: null,
            appointments: [],
            renderCalendar: false,
            clinicResults: null,
            activeMonth: null,
            activeYear: null,
            activeDay: null,
            apd1: null,
            apd2: null,
            apd3: null,
            file: null,
            fileName:null

        }

        this.menuSelect.bind(this);
        this.getTodoList.bind(this);
        this.renderCell.bind(this);

        Date.prototype.isSameDateAs = function (pDate) {
            return (
                this.getFullYear() === pDate.getFullYear() &&
                this.getMonth() === pDate.getMonth() &&
                this.getDate() === pDate.getDate()
            );
        }
    }
    saveFile(e) {
        if (e) {
            console.log("FILEEEEE", e.target.files);
            this.setState({ file: e.target.files[0]});
            this.setState({ fileName: e.target.files[0].name});
        }
        
    }
    uploadFile() {
        console.log("STEFAN",this.state.file);
        if (this.state.file) {
            const formCollection = new FormData();
            formCollection.append("file", this.state.file)
            formCollection.append("fileName", this.state.fileName)
           
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': 'Bearer ' + this.props.userInfo.token },
                body: formCollection
            };
            fetch('/api/User/UploadPicture/clinic_profile_imgs/1000000.jpg', requestOptions)
                .then(response => response.json())
                .then(data => {
                    $(".messageBoxWarpper").show();
            })
            
        }
       
    }
    componentWillMount() {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = new Date();
        const amonth = monthNames[d.getMonth()];
        this.setState({ activeMonth: amonth });
        this.setState({ activeYear: d.getFullYear() });
        this.setState({ activeDay: d.getDate() });
        var numd = d.getDate()
        if (numd <= 3) {
            this.setState({ apd1: 3 });
            this.setState({ apd2: 5 });
            this.setState({ apd3: 6 });
        } else {
            
            if (numd > 27) {
                this.setState({ apd1: numd });
                this.setState({ apd2: numd });
                this.setState({ apd3: numd });
            } else {
                this.setState({ apd1: numd });
                this.setState({ apd2: numd+1 });
                this.setState({ apd3: numd+2 });
            }
            
        }

        console.log(this.props.userInfo);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInfo.token },

        };
        fetch('/api/Booking/ClinicAppointmentList', requestOptions)
            .then(response => response.json())
            .then(data => {
                data.sort(function (a, b) {
                    return a.creationTime.localeCompare(b.creationTime);
                });
                data.reverse();
                this.setState({ appointments: data });
                console.log(data);
                this.setState({renderCalendar : true})
            })

        //fetch('/api/clinic/1000000', requestOptions)
        //.then(response => response.json())
        //.then(data => {
        //    console.log(data);
        //    this.setState({ clinicResults: data }, () => {
        //        console.log("khm222", this.props.userInfo);
        //    });


        //});
        fetch('/api/User/GetAdminClinic', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("OVde gledaj",data);
                this.setState({ clinicResults: data })


        });
    }
    handleChangeClinicName = e => {
        console.log(e);
        var clinic = this.state.clinicResults;
        clinic.name = e.target.value;
        this.setState({ clinicResults: clinic });
    };
    handleSaveChanges = () => {
        var clinicInfo = this.state.clinicResults;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + this.props.userInfo.token
            },
            body: JSON.stringify(clinicInfo)
        };
        fetch('/api/User/UpdateAdminClinic', requestOptions).then(data => {
                $(".messageBoxWarpper").show();
        })
    }
    selectMenu(menuchose) {
        $(".AppointmentsHolder").hide();
        $("#" + menuchose).show();

        $(".useroptbtn").removeClass("selectedBtn");
        $("#btn" + menuchose).addClass("selectedBtn");
    }
    goToClinicPage(id) {
        
        window.location.href = "/clinic/"+id;
    }
    cancelBooking(index , date,time) {
        var rdate = new Date(date+" "+time);


        var currentdate = new Date();

        if (Math.abs(rdate - currentdate) >= 72 * 60 * 60 * 1000) {
     
            this.setState({ cancelIndex: index });
            $(".sucBooking").show();
            //this.props.cancelBooking(index);
            
        }else {
            $(".missingInfo").show();
        }
        
    }

    

    menuSelect(option) {
        if (option == "appointments") {
            $(".useroptbtn").removeClass("selectedBtn");
            $("#app_btn").addClass("selectedBtn");
            $("#appointments").show();
            $("#reviews").hide();

        }
        if (option == "reviews") {
            $(".useroptbtn").removeClass("selectedBtn");
            $("#rev_btn").addClass("selectedBtn");
            $("#appointments").hide();
            $("#reviews").show();
        }
    }

    renderCell(date) {
    const list = this.getTodoList(date);
    const displayList = list.filter((item, index) => index < 2);

    if (list.length) {
        const moreCount = list.length - displayList.length;
        const moreItem = (
            <li>
                <Whisper
                    placement="top"
                    trigger="click"
                    speaker={
                        <Popover>
                            {list.map((item, index) => (
                                <p key={index}>
                                    <b>{item.time}</b> - <b>Doctor</b> ({item.doctor}) <b>Client</b> ({item.client}) <b>Procedure</b> ({item.procedure})
                                </p>
                            ))}
                        </Popover>
                    }
                >
                    <a>more info</a>
                </Whisper>
            </li>
        );

        return (
            <ul className="calendar-todo-list">
                {displayList.map((item, index) => (
                    <li key={index}>
                        <Badge /> <b>{item.time}</b>
                    </li>
                ))}
                {moreItem}
            </ul>
        );
    }

    return null;
}

    

    getTodoList(date) {
        
        const day = date.getDate();

        console.log(this.state.appointments);

        var result = [];

        for (var a of this.state.appointments) {
            var apDate = new Date(a.start);
            

            console.log(date);
            if (date.isSameDateAs(apDate)) {
                console.log(a);
                result.push({ time: a.start.split("T")[1].substring(0, 5) + "-" + a.end.split("T")[1].substring(0, 5), doctor: a.doctor.name, client: a.user.name, procedure: a.threatmantDetail.name });
            }
            
        }

    //switch (day) {
    //    case 10:
    //        return [
               
    //        ];
    //    case 15:
    //        return [
    //            { time: '09:30-10:00', doctor: 'Marko Markovic', client:'Petar Petrovic', procedure : 'Popravka zuba' },
    //            { time: '10:30-11:00', doctor: 'Marko Markovic', client: 'Petar Petrovic', procedure: 'Popravka zuba' },
    //            { time: '11:30-12:00', doctor: 'Marko Markovic', client: 'Petar Petrovic', procedure: 'Popravka zuba' },
    //            { time: '13:30-14:00', doctor: 'Marko Markovic', client: 'Petar Petrovic', procedure: 'Popravka zuba' },
    //        ];
    //    default:
    //        return [];

     return result
    
}
    
    render() {

        

        if (this.props.userLogedIn) {
            let calendarRender = "";
            if (this.state.renderCalendar) {
                calendarRender =<Calendar bordered renderCell={this.renderCell.bind(this)} />;
            }
            return (
                <div className="UserPageHolder">
                    <MessageUserBox/>
                    <div className="missingInfo">
                        <h5>Sorry you appointment cannot be canceled because its within 72h from now. </h5>
                        <button onClick={() => { $(".missingInfo").hide() }}> OK </button>
                    </div>
                    <div className="sucBooking">
                        <h5>Are you sure you want to cancel the appointment? </h5>
                        <button className='yesbtn' onClick={() => { this.props.cancelBooking(this.state.cancelIndex); $(".sucBooking").hide() }}> Yes </button>
                        <button onClick={() => { $(".sucBooking").hide() }}> No </button>
                    </div>

                    <table>
                        <tr>
                            <td>
                                <div className="UserMenu">
                                    <br></br>
                                    <br></br>
                                    <img src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/1000000.jpg'}/>
                                    <h4>Lentrod</h4>
                                    <button onClick={() => { this.selectMenu("calendar") }} id="btncalendar" className="useroptbtn selectedBtn">Calendar</button>
                                    <button onClick={() => { this.selectMenu("doctors") }} id="btndoctors" className="useroptbtn ">Doctors</button>
                                    <button onClick={() => { this.selectMenu("pricechart") }} id="btnpricechart" className="useroptbtn ">PriceChart</button>
                                    <button onClick={() => { this.selectMenu("billing") }} id="btnbilling" className="useroptbtn ">Billing</button>
                                    <button onClick={() => { this.selectMenu("clinicSettings") }} id="btnclinicSettings" className="useroptbtn ">Settings</button>
                                  
                                    <div></div>
                                </div>
                            </td>

                            <td>
                                <div style={{ display: "block" }} id="calendar" className="AppointmentsHolder">
                                    
                                    {calendarRender}

                                </div>
                                <div style={{ display: "none" }} id="doctors" className="AppointmentsHolder">
                                    <h3>  </h3>
                                    <br></br>
                                    <br></br>
                                    <hr className="custionHR"></hr>
                                    <div className="doctorHolderClinicAdmin">
                                        <table className="tabelDocHold" >
                                            <tr>
                                                <td colSpan="2" rowSpan="4" style={{ verticalAlign: "top" }}>
                                                    <br></br>
                                                    <br></br>
                                                    <img src={docimg1} />
                                                    <br></br>
                                                    <br></br>
                                                    <p>Change/Upload a new photo</p>

                                                </td>
                                                <td colSpan="2">
                                                    <h5>First name</h5>
                                                    <h4>Marcus  <p style={{ float: "right" }}>Change</p></h4>
                                                   
                                                </td>
                                                <td style={{ textAlign: "right", verticalAlign: "top" }} colSpan="2" rowSpan="4">
                                                    <br></br>
                                                    <br></br>
                                                    <p>Preview</p>
                                                    <h2>Dr. Marcus Lentrod</h2>
                                                    <h3>Dentist</h3>
                                                    <br></br>
                                                    <br></br>
                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <h5>Last name</h5>
                                                    <h4>Lentrod  <p style={{ float: "right" }}>Change</p></h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <h5>Tittle</h5>
                                                    <h4>Dr.  <p style={{ float: "right" }}>Change</p></h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <h5>Specialization</h5>
                                                    <h4>Dentist  <p style={{ float: "right" }}>Change</p></h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6"></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "left", verticalAlign: "top" }}>
                                                    <div>
                                                        Mon
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork}  className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Tue
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Wed
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Thu
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Fri
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Sat
                                                        <br></br>
                                                        <br></br>
                                                        <img src={nowork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Sun
                                                        <br></br>
                                                        <br></br>
                                                        <img src={nowork} className="imgDay" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "left", verticalAlign: "top" }}>
                                                    Doctor working hours:
                                                    <label>from</label> <input type="time" value="09:00" /> <label>to</label> <input type="time" value="18:00" />
                                                    <button style={{float:"right"}}>Delete this doctor</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="doctorsInfoDiv">
                                        Above, you are dfining when this specific doctor is present at your clinic.
                                        For example, if the doctor is working from Monday to Friday, then you will
                                        need to check days from Monday to Friday. By doing so no booking from our
                                        platform will arrive on the days that this doctor is not present at the clinic.
                                        <br></br>
                                        <br></br>
                                        In case of vaction, or any other unplanned circumstance, you can block entire days or time frames
                                        (ex. Lunch break or longer periods from 12pm to 16pm) directly on the calendar page.
                                        <br></br>
                                        <br></br>
                                        You also need to define the working hours of each specialist. For a example specialst is present at the clinic from
                                        9h to 17h
                                    </div>
                                    <h4 className="h4servicesDoctor">Services offered by the doctor</h4>
                                    <table className="doctorTreatmentTable">
                                        <thead>
                                            <td>Treatment name</td>
                                            <td>Treatmen duration</td>
                                            <td>Edit/Delete</td>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <br></br>
                                    <button className="docaddthreat">Add new treatment</button>
                                    <br></br>
                                    <br></br>
                                    <hr className="custionHR"></hr>
                                    <div className="doctorHolderClinicAdmin">
                                        <table className="tabelDocHold" >
                                            <tr>
                                                <td colSpan="2" rowSpan="4" style={{ verticalAlign: "top" }}>
                                                    <br></br>
                                                    <br></br>
                                                    <img src={docimg2} />
                                                    <br></br>
                                                    <br></br>
                                                    <p>Change/Upload a new photo</p>

                                                </td>
                                                <td colSpan="2">
                                                    <h5>First name</h5>
                                                    <h4>Michael  <p style={{ float: "right" }}>Change</p></h4>

                                                </td>
                                                <td style={{ textAlign: "left", verticalAlign: "top" }} colSpan="2" rowSpan="4">
                                                    <br></br>
                                                    <br></br>
                                                    <p>Priview</p>
                                                    <h2>Dr. Michael Lentrod</h2>
                                                    <h3>Dentist</h3>
                                                    <br></br>
                                                    <br></br>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <h5>Last name</h5>
                                                    <h4>Lentrod  <p style={{ float: "right" }}>Change</p></h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <h5>Tittle</h5>
                                                    <h4>Dr.  <p style={{ float: "right" }}>Change</p></h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <h5>Specialization</h5>
                                                    <h4>Dentist  <p style={{ float: "right" }}>Change</p></h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6"></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "left", verticalAlign: "top" }}>
                                                    <div>
                                                        Mon
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Tue
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Wed
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Thu
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Fri
                                                        <br></br>
                                                        <br></br>
                                                        <img src={yeswork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Sat
                                                        <br></br>
                                                        <br></br>
                                                        <img src={nowork} className="imgDay" />
                                                    </div>
                                                    <div>
                                                        Sun
                                                        <br></br>
                                                        <br></br>
                                                        <img src={nowork} className="imgDay" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "left", verticalAlign: "top" }}>
                                                    Doctor working hours:
                                                    <label>from</label> <input type="time" value="09:00" /> <label>to</label> <input type="time" value="18:00" />
                                                    <button style={{ float: "right" }}>Delete this doctor</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <h4 className="h4servicesDoctor">Services offered by the doctor</h4>
                                    <table className="doctorTreatmentTable">
                                        <thead>
                                            <td>Treatment name</td>
                                            <td>Treatmen duration</td>
                                            <td>Edit/Delete</td>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                            <tr>
                                                <td>Facelift</td>
                                                <td>3 hours</td>
                                                <td>Edit</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <br></br>
                                    <button className="docaddthreat">Add new treatment</button>
                                    <br></br>
                                    <br></br>
                                    <hr className="custionHR"></hr>
                                    <button style={{ float: "right", marginRight:"10%" }} className="docaddthreat">Add new doctor</button>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </div>
                                <div style={{ display: "none" }} id="pricechart" className="AppointmentsHolder" >
                                    <h4 className="h4servicesDoctor">Published price chart</h4>
                                    <div className="treatmentOfferClinic"><h5>Oral exams</h5><h5 style={{ textAlign: "right" }}>Price range : 50 - 65 Euros </h5>
                                        <button id="btnshowhideOE" onClick={() => {
                                            if ($('.info_oralExams').is(":visible")) {
                                                $('.info_oralExams').hide()
                                                $("#btnshowhideOE").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_oralExams').show();
                                                $("#btnshowhideOE").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }} className="info_oralExams">
                                    <br></br>
                                    <br></br>
                                    <table className="doctorTreatmentTable">
                                        <tr><td style={{ textAlign: "left" }}>• Comprehensive oral exam</td><td style={{ textAlign: "right" }}>65 €</td></tr>
                                        <tr><td style={{ textAlign: "left" }}>• Periodic oral exam</td><td style={{ textAlign: "right" }}>50 €</td></tr>
                                    </table>

                                    <hr className="custionHR"></hr>
                                    <br></br>
                                    <div className="treatmantInfoDiv">
                                        <h5>Description</h5>
                                        <p>
                                            During the oral examination, our professionals will conduct a manual and visual screening of the mouth and the lymph nodes. Examining the soft and hard palate and the lips’ inner and outer part are the next step.  The dentist will then investigate under the mouth, and then the cheeks, and behind the molars. Preventative dental care is always the main focus of a dentist. During the oral exam, our dentist is also screening for cancer.
                                        </p>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="treatmantInfoDiv">
                                        <h5>Peconditions</h5>
                                        <p>Patients are required to wash their teeth before visiting the dentist office.</p>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="treatmantInfoDiv">
                                        <h5>Duration</h5>
                                        <p>About 45 minutes on average.</p>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="treatmantInfoDiv">
                                        <h5>Recovery</h5>
                                        <p>
                                            No recovery
                                        </p>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="CancelationPolicy">
                                        <h4>Cancelation policy</h4>
                                        <div className="numhorus"><span>48 hours</span></div>
                                        <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>

                                        
                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Bonding Teeth repair & Sealings</h5><h5 style={{ textAlign: "right" }}>Price range : 60 - 150 Euros </h5>
                                        <button id="btnshowhideBT" onClick={() => {
                                            if ($('.info_bondtr').is(":visible")) {
                                                $('.info_bondtr').hide()
                                                $("#btnshowhideBT").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_bondtr').show();
                                                $("#btnshowhideBT").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }} className="info_bondtr">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Mercury Fillings</td><td style={{ textAlign: "right" }}>120 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Composite Fillings </td><td style={{ textAlign: "right" }}>150 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Fissure sealing (per tooth)</td><td style={{ textAlign: "right" }}>60 €</td></tr>
                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                Having a filling is typically an in-office procedure. Children and anyone with severe dental anxiety may require sedation or even general anesthesia. The steps may vary, depending on the filling’s location and the materials used. But the general steps are:
                                                <br></br>
                                                <ol type="a">

                                                    <br></br>
                                                    <li>
                                                        The dentist applies a numbing gel to the gums. Once this has taken effect, they inject a local anesthetic into the gum.
                                                    </li>
                                                    <br></br>
                                                    <li>
                                                        Using a drill or another specialized tool, the dentist removes the decayed area of the tooth.
                                                    </li>
                                                    <br></br>
                                                    <li>
                                                        Then dentist fill the hole in the tooth.
                                                    </li>
                                                    <br></br>
                                                    <li>
                                                        Lastly, the dentist will polish the filling and may adjust it so that the person’s bite feels normal.
                                                    </li>
                                                    <br></br>
                                                </ol>
                                                <br></br>
                                            
                                                If they use a composite filling material, the dentist also needs to “cure,” or harden, it with a special light.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>Patients are required to wash their teeth before visiting the dentist office.</p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>
                                                In general, a tooth filling takes an hour or less. A simple filling may take as little as 20 minutes. A larger filling or multiple fillings can take longer. Also, depending on the materials used for the filling, it could take longer, or require a second visit.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                The dentist may recommend not eating or drinking until the numbing medication wears off. This is to prevent a person from accidentally biting their tongue or the inside of their cheek.
                                                <br></br>
                                                <br></br>
                                                There may be some minor soreness or discomfort, and over-the-counter medication, such as ibuprofen (Advil, Motrin) or acetaminophen (Tylenol) should help.
                                                <br></br>
                                                <br></br>
                                                A person may experience increased sensitivity to hot and cold after a filling. This can occur due to minor nerve irritation, gum irritation, or inflammation due to the drilling. These sensations typically improve with time.

                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>48 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>X-rays</h5><h5 style={{ textAlign: "right" }}>Price range : 25 - 155 Euros </h5>
                                        <button id="btnshowhideXR" onClick={() => {
                                            if ($('.info_Xray').is(":visible")) {
                                                $('.info_Xray').hide()
                                                $("#btnshowhideXR").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_Xray').show();
                                                $("#btnshowhideXR").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }} className="info_Xray">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• X-ray (per exposure)</td><td style={{ textAlign: "right" }}>25 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• X-ray full mouth</td><td style={{ textAlign: "right" }}>155  €</td></tr>
                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                It is one of the dentist’s most important diagnostic tools, giving him or her a better picture of what’s going on with your teeth than simply looking in your mouth. Dental radiographs work by using a small, controlled burst of radiation to create a picture of the tooth. Cavities, infections, and other conditions show up as dark spots on the lighter image of the tooth.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>Patients are required to wash their teeth before visiting the dentist office.</p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>A bitewing x-ray of a single tooth might take only a few minutes. A full mouth series, in which teeth are x-rayed one or two at a time, typically takes about 25 minutes
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                No recovery
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>48 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Whitening and cleaning</h5><h5 style={{ textAlign: "right" }}>Price range : 120 - 700 Euros </h5>
                                        <button id="btnshowhideWC" onClick={() => {
                                            if ($('.info_WC').is(":visible")) {
                                                $('.info_WC').hide()
                                                $("#btnshowhideWC").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_WC').show();
                                                $("#btnshowhideWC").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }} className="info_WC">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Teeth Bleaching</td><td style={{ textAlign: "right" }}>700 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Zoom Whitening</td><td style={{ textAlign: "right" }}>700  €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Scale and clean</td><td style={{ textAlign: "right" }}>120  €</td></tr>
                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                            All in-office teeth whitening procedures follow a somewhat standard routine:
                                            <ul>
                                                <li>
                                                    Take a shade measurement of your teeth before the procedure begins.
                                                </li>
                                                <li>
                                                    Polish your teeth using pumice.
                                                </li>
                                                <li>
                                                        Place a cheek retractor to expose all the teeth that are visible when you smile.
                                                </li>
                                                <li>
                                                        Cover your eyes, gums, and lips, to protect them from the bleaching gel.
                                                </li>
                                                <li>
                                                        Apply a special whitening gel to your teeth.
                                                    </li>
                                                    <li>
                                                        Remove the previous layer during each session.
                                                    </li>
                                                    <li>
                                                        Make you rinse your mouth with a lot of water, and polish your teeth with pumice again (if necessary).
                                                    </li>
                                                    <li>
                                                        Assess the degree of teeth whitening to gauge whether the procedure should be repeated or not.
                                                    </li>
                                                    <li>
                                                        Apply more whitening gel if necessary.
                                                    </li>
                                                    <li>
                                                        Remove your cheek retractors so that you can rinse your mouth.
                                                    </li>
                                                    <li>
                                                        Take your final teeth shade.
                                                    </li>
                                            </ul>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>
                                                Here is what you need to do before teeth bleaching at a dental office:
                                                <ul>
                                                    <li>
                                                        Should brush your teeth.
                                                    </li>
                                                    <li>
                                                        Consider using lip balm before to avoid dry or sore lips.
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>Teeth bleaching will take place in three to four 15-20 minutes sessions.</p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                The procedure is neither painful nor uncomfortable. While there are a few general behavioral practices to follow after your teeth whitening treatment to maximize results, these in no way impact recovery.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>48 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Braces </h5><h5 style={{ textAlign: "right" }}>Price range : 4500 - 5000 Euros </h5>
                                        <button id="btnshowhideBRAC" onClick={() => {
                                            if ($('.info_BRAC').is(":visible")) {
                                                $('.info_BRAC').hide()
                                                $("#btnshowhideBRAC").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_BRAC').show();
                                                $("#btnshowhideBRAC").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }} className="info_BRAC">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Invisalign </td><td style={{ textAlign: "right" }}>4500 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Traditional Braces</td><td style={{ textAlign: "right" }}>5000  €</td></tr>
                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                Dental braces are wire-based appliances that orthodontists use to correct crowded and misaligned teeth or jaws. Many people who need dental braces get them during their early teenage years, but adults also can benefit from wearing braces. The goal of dental braces is to properly align your teeth and jaws to produce an even bite and pleasing smile.
                                                <br></br>
                                                <br></br>
                                                For minor corrections, an option is a series of customized, removable appliances called clear aligners or "invisible braces." These may have a more acceptable appearance to some adults.
                                                <br></br>
                                                <br></br>
                                                Treatment is done in three phases: the initial placement of the braces (or clear aligners), periodic adjustments and use of a retainer after the braces are removed.

                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>
                                                Here is what you need to do before teeth bleaching at a dental office:
                                                <ul>
                                                    <li>Oral exam. Your orthodontist conducts a full exam of your teeth, jaws and mouth.</li>
                                                    <li>X-rays. You'll get a series of X-rays to determine the position of teeth. The most common is the panoramic X-ray, which shows all the upper and lower teeth in biting position and any teeth still developing within the jaws. Special head X-rays also may help determine the size, position and relationship of jaws to teeth. These 3D X-rays give a better idea of the true position of the teeth to each other.
                                                    </li>
                                                    <li>Plaster models. You'll bite into soft material, which remains on your teeth for a few minutes. From this impression, a plaster model of your teeth (dental cast) is created and the orthodontist evaluates your bite. In some cases, this dental cast may be scanned into a digital format for further evaluation or treatment decisions.</li>
                                                    <li>Potential tooth extraction. If your mouth is very overcrowded, there may be limited or no room in the jaw for all the existing teeth. Your orthodontist may recommend removing one or more permanent teeth to allow room for the remaining teeth to fit comfortably. This allows the teeth to fit together better and also allows for adequate space for cleaning.</li>
                                                    <li>Other procedures. In severe cases, where tooth movement alone will not correct a bite that's significantly out of alignment, jaw repositioning surgery is required in combination with orthodontics.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>
                                                The entire process of bonding the brackets and placing the arch wires can take anywhere from 10 to 20 minutes. The length of time is often determined by the time it takes to position the components correctly in order to treat the person’s specific condition.
                                                <br></br>
                                                <br></br>
                                                After fixed braces are placed on your teeth, your orthodontist adjusts them periodically by tightening or bending the interconnecting wires.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                Five days after placement of braces any initial discomfort associated with the braces should be gone or much improved. The teeth will have gradually acclimated to the braces, and eating should be much easier.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>72 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 72 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Root canal treatments</h5><h5 style={{ textAlign: "right" }}>Price range : 200 Euros </h5>
                                        <button id="btnshowhideRCT" onClick={() => {
                                            if ($('.info_RCT').is(":visible")) {
                                                $('.info_RCT').hide()
                                                $("#btnshowhideRCT").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_RCT').show();
                                                $("#btnshowhideRCT").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }} className="info_RCT">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Root canal treatment - one canal </td><td style={{ textAlign: "right" }}>200 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Root canal treatment - additional canal</td><td style={{ textAlign: "right" }}>200  €</td></tr>
                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                Root canal treatment is a dental procedure used to treat infection at the center of a tooth. Root canal treatment is not painful and can save a tooth that might otherwise have to be removed completely.
                                                <br></br>
                                                <br></br>
                                                Root canal treatment is only required when dental X-rays show that the pulp has been damaged by a bacterial infection.
                                                <br></br>
                                                <br></br>
                                                Before having root canal treatment, you'll usually be given a local anesthetic.
                                                <br></br>
                                                <br></br>
                                                This means the procedure should be painless and no more unpleasant than having a filling.
                                                <br></br>
                                                <br></br>
                                                Root canal treatment is usually successful. In about 9 out of 10 cases a tooth can survive for up to 10 years after root canal treatment.
                                                <br></br>
                                                <br></br>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>
                                                Patients are required to wash their teeth before coming to the dental office.
                                                <br></br>
                                                <br></br>
                                                Dental X-rays are required which are usually made in dental office directly
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>
                                                A root canal can take anywhere from 90 minutes to 3 hours. It can sometimes be done in one appointment but may require two.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                <ul>
                                                    <li>You should avoid biting on hard foods until your treatment is complete.</li>
                                                    <li>After your final treatment, your restored tooth should no longer be painful, although it may feel sensitive for a few days.</li>
                                                    <li>You can take over-the-counter painkillers, such as paracetamol or ibuprofen, to relieve any discomfort.</li>
                                                    <li>Return to your dentist if you still have pain or swelling after using painkillers.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>48 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Crowns </h5><h5 style={{ textAlign: "right" }}>Price range : 1250 Euros </h5>
                                        <button id="btnshowhideCRW" onClick={() => {
                                            if ($('.info_CRW').is(":visible")) {
                                                $('.info_CRW').hide()
                                                $("#btnshowhideCRW").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_CRW').show();
                                                $("#btnshowhideCRW").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{ display:"none" }} className="info_CRW">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Full crown (veneered) </td><td style={{ textAlign: "right" }}>1250 €</td></tr>
                                         </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                Dental crowns are tooth-shaped “caps” that can be placed over your tooth. The crown restores the tooth's shape, size, strength and appearance. The dental crown is cemented into place on your tooth and it covers the visible portion of the tooth.
                                                <br></br>
                                                <br></br>
                                                You may need a dental crown for several reasons, including:
                                                <ul>
                                                    <li>
                                                        Protecting a weak tooth (possibly from decay) from breaking or to keep the weak tooth together if parts of it are cracked.
                                                    </li>
                                                    <li>
                                                        Restoring a broken tooth or a severely worn down tooth.
                                                    </li>
                                                    <li>
                                                        Covering and supporting a tooth with a large filling and not much tooth remaining.
                                                    </li>
                                                    <li>
                                                        Holding a dental bridge in place.
                                                    </li>
                                                    <li>
                                                        Covering misshapen or severely discolored teeth.
                                                    </li>
                                                    <li>
                                                        Covering a dental implant.
                                                    </li>
                                                    <li>
                                                        Covering a tooth that’s been treated with a root canal.
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>
                                                You will typically have two visits to the dentist to prepare for a dental crown. In some cases, you may have a dental crown made in your dentist’s office.
                                                <br></br>
                                                <br></br>
                                                During the first visit, the tooth that’s going to receive the crown is examined and prepared. X-rays are taken of the tooth and the bone around it. After reshaping the tooth, a paste or putty is used to make a copy (also called a impression) of the tooth that’s going to receive the crown.
                                                <br></br>
                                                <br></br>
                                                At the second visit, the permanent crown is placed on your tooth. First, the temporary crown is removed and the fit and color of the permanent crown is checked.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>
                                                You may have to wait about 1 to 2 hours until the crown is made. Once the crown is ready, your dentist cements it into place. The entire process takes about 2 to 4 hours
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                Discomfort or sensitivity: A newly crowned tooth may be sensitive immediately after the procedure as the anesthesia begins to wear off. If the crowned tooth still has a nerve in it, you may experience some heat and cold sensitivity. Your dentist might recommend that you brush your teeth with toothpaste designed for sensitive teeth. Pain or sensitivity that happens when you bite down usually means that the crown is too high on the tooth. If this is the case, call your dentist. This problem is easily fixed.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>48 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Dentures </h5><h5 style={{ textAlign: "right" }}>Price range : 800 - 3700 Euros </h5>
                                        <button id="btnshowhideDEN" onClick={() => {
                                            if ($('.info_DEN').is(":visible")) {
                                                $('.info_DEN').hide()
                                                $("#btnshowhideDEN").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_DEN').show();
                                                $("#btnshowhideDEN").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{display:"none"}} className="info_DEN">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Denture (complete maxillary)  </td><td style={{ textAlign: "right" }}>1100 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Endosteal implants</td><td style={{ textAlign: "right" }}>3500  €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Subperiosteal Implants </td><td style={{ textAlign: "right" }}>3700 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Traditional dental bridges</td><td style={{ textAlign: "right" }}>1000  €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Maryland dental bridge </td><td style={{ textAlign: "right" }}>800 €</td></tr>
                                            <tr><td style={{ textAlign: "left" }}>• Implant-supported dental bridge</td><td style={{ textAlign: "right" }}>1800  €</td></tr>

                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                <b>Dental implant</b> treatment is usually a two-step process.   Following any preparatory treatment, such as extractions or bone grafting, a titanium post is placed into a new, healing or healed extraction site.  This will eventually act as your new tooth root.  The implant post may then be completely covered and hidden beneath a layer of gum (you won’t be able to see it in your mouth). The stitches are normally removed or dissolve by themselves after seven to fourteen days.
                                                <br></br>
                                                <br></br>
                                                <b>Dental bridge</b> up of two or more crowns for the teeth on either side of the gap -- these two or more anchoring teeth are called abutment teeth -- and a false tooth/teeth in between. These false teeth are called pontics and can be made from gold, alloys, porcelain, or a combination of these materials
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>
                                                <b>Dental implant</b> - During your initial consultation, you’ll probably need an x-ray, you may need a CT scan, and the dentist will take impressions of your teeth.
                                                <br></br>
                                                <br></br>
                                                <b>Dental bridge</b> - Patients with gum disease should have their periodontal issues treated before having bridges placed.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>
                                                <b>Dental implant</b> - The time necessary to place the dental implants varies based on the technique your dentist chooses to use and the location of the tooth, but generally, the procedure takes about one hour per implant.
                                                <br></br>
                                                <br></br>
                                                <b>Dental bridge</b> - Usually, dental bridge treatment requires at least two visits for the process. The time taken will be an hour to two hours.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                <b>Dental Implant </b> - In a straightforward case, many dentists will want to leave the gum to heal and the implant to fuse (osseointegrate) with the jawbone, before fitting permanent, replacement teeth.  There is usually a period of healing lasting from six weeks to six months.  The upper jaw usually takes a little longer, due to softer bone.
                                                <br></br>
                                                <br></br>
                                                You may also need to make several visits to your dentist during the following few weeks, for the adjustment of temporary teeth or dentures and so that the healing of the implant site can be monitored.
                                                <br></br>
                                                <br></br>
                                                <b>Dental Bridge </b>- Recovery time after a dental bridge procedure can vary depending on what device is used to anchor the bridge in place. If a dental crown is utilized, recovery will be much quicker, generally not lasting longer than 1 day. If implants are needed to secure the bridge, recovery time may last as long as 1 week.</p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>72 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 72 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <div className="treatmentOfferClinic"><h5>Tooth extractions</h5><h5 style={{ textAlign: "right" }}>Price range  : 90 Euros </h5>
                                        <button id="btnshowhideTE" onClick={() => {
                                            if ($('.info_TE').is(":visible")) {
                                                $('.info_TE').hide()
                                                $("#btnshowhideTE").html('<i class="arrow down"></i>');
                                            } else {
                                                $('.info_TE').show();
                                                $("#btnshowhideTE").html('<i class="arrow up"></i>');
                                            }
                                        }}>
                                            <i className="arrow down"></i>
                                        </button>
                                    </div>
                                    <div style={{display:"none"}} className="info_TE">
                                        <br></br>
                                        <br></br>
                                        <table className="doctorTreatmentTable">
                                            <tr><td style={{ textAlign: "left" }}>• Simple (non-surgical) tooth extraction  </td><td style={{ textAlign: "right" }}>90 €</td></tr>
                                        </table>

                                        <hr className="custionHR"></hr>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Description</h5>
                                            <p>
                                                A simple tooth extraction involves the removal of a tooth that is visible in the mouth. This could mean removing a badly damaged or decayed tooth, or removing teeth prior to getting braces.
                                                <br></br>
                                                <br></br>
                                                During a simple extraction, your dentist will numb the tooth and gum tissue and loosen the tooth with an instrument called an elevator before removing it with dental forceps. A surgical extraction is a more complex procedure used for a tooth that may have broken off at the gum line or has not come into the mouth yet.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Peconditions</h5>
                                            <p>
                                                Patients are required to wash their teeth before visiting the dentist’s office.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Duration</h5>
                                            <p>
                                                If you're just having one tooth extracted, the entire process can be completed in 20-40 minutes. However, if you're having multiple teeth extracted, expect to spend a little more time in our office. Each additional tooth will take another 3-15 minutes of appointment time, depending on its location.
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="treatmantInfoDiv">
                                            <h5>Recovery</h5>
                                            <p>
                                                It will take roughly 1-2 weeks for your tooth extraction site to completely heal; however, if you notice any of the following symptoms or signs, be sure to contact our doctors as soon as possible:
                                                <ul>
                                                    <li>
                                                        Fever
                                                    </li>
                                                    <li>
                                                        Intense pain in the jaw or gums
                                                    </li>
                                                    <li>
                                                        Numbness in the mouth
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="CancelationPolicy">
                                            <h4>Cancelation policy</h4>
                                            <div className="numhorus"><span>48 hours</span></div>
                                            <div className="bold"><span>All treatments in this group can be canceled 48 hours prior to the appointment.</span></div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <button className="editButtonAAA">Edit</button>


                                        <hr className="custionHR"></hr>
                                    </div>
                                    <hr className="custionHR"></hr>
                                    <div className="treatmentOfferClinic addNewThreatment"><h5>+ Add new product/product group</h5></div>
                                    <br></br>
                                    <br></br>

                                </div>
                                <div style={{ display: "none" }} id="billing" className="AppointmentsHolder" >
                                    <h4 className="h4servicesDoctor">{this.state.activeMonth} {this.state.activeYear}</h4>
                                    <img src={billingpiecharts} />
                                    <div className="chartsExplanation">
                                        <p>
                                            <a className="billingappA">Apointments</a> : Refers to the number of appointments that come through the Health-Scanner platform.
                                        </p>
                                        <p>
                                            <a className="billingaeA">Amount earned</a> : Refers to the amount that has been earned through the appointments that come trough Health-Scanner platform.
                                        </p>
                                        <p>
                                            <a className="billinghsA">Healt-Scanner bar</a> : Refers to the amount owed to Health-Scanner and equals to 15% of the amount earned.
                                        </p>
                                        <p>
                                            The calculation starts every 20 th of a month and ends on the 20 th of the following month.
                                        </p>
                                    </div>
                                    <hr className="custionHR"></hr>
                                    <div className="billingBookingCard">
                                        <table >
                                            <tbody>
                                            <tr>
                                                    <td><img width="130" height="185" src={client_1} /></td>
                                                <td>
                                                    <p>Name : Mary Smith</p>
                                                        <p>Appointmant date : {this.state.apd1 }th of {this.state.activeMonth}</p>
                                                    <p><a className="billinghsA">Booked</a> : Dental Crown- composite</p>
                                                    <p><a className="billingaeA">Booked value</a> : 520 euros</p>
                                                </td>
                                                <td>
                                                    <button style={{ background:"rgb(21, 113, 180)"}}>Edit booked value</button>
                                                    <button style={{ background: "rgb(45, 62, 80)" }}>Confirm booked value</button>
                                                </td>
                                                </tr>
                                                </tbody>
                                        </table>
                                    </div>
                                    <div className="billingBookingCard">
                                        <table >
                                            <tbody>
                                            <tr>
                                                    <td><img width="130" height="185"  src={client_2} /></td>
                                                <td>
                                                        <p>Name : Jennifer Williams</p>
                                                        <p>Appointmant date : {this.state.apd2}th of {this.state.activeMonth}</p>
                                                    <p><a className="billinghsA">Booked</a> : Braces invisalign</p>
                                                    <p><a className="billingaeA">Booked value</a> : 6.000 euros</p>
                                                </td>
                                                <td>
                                                    <button style={{ background: "rgb(21, 113, 180)" }}>Edit booked value</button>
                                                    <button style={{ background: "rgb(45, 62, 80)" }}>Confirm booked value</button>
                                                </td>
                                                </tr>
                                                </tbody>
                                        </table>
                                    </div>
                                    <div className="billingBookingCard">
                                        <table >
                                            <tbody>
                                            <tr>
                                                    <td><img width="130" height="185"  src={client_3} /></td>
                                                <td>
                                                    <p>Name : James Brown</p>
                                                        <p>Appointmant date : {this.state.apd3}th of {this.state.activeMonth}</p>
                                                        <p><a className="billinghsA">Booked</a> : Tooth Filling</p>
                                                    <p><a className="billingaeA">Booked value</a> : 170 euros</p>
                                                </td>
                                                <td>
                                                    <button style={{ background: "rgb(21, 113, 180)" }}>Edit booked value</button>
                                                    <button style={{ background: "rgb(45, 62, 80)" }}>Confirm booked value</button>
                                                </td>
                                                </tr>
                                                </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div style={{ display: "none" }} id="clinicSettings" className="AppointmentsHolder" >

                                    <h4 className="h4servicesDoctor">Personal information</h4>
                                    <table class="tablePinformation">
                                        <tbody>
                                            <tr>
                                                <td colspan="3">
                                                    <img src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/1000000.jpg'} />
                                                    <br></br>
                                                    <br></br>
                                                    <input
                                                        type="file"
                                                        id="profpic" name="profpic"
                                                        accept="image/jpeg"
                                                        onChange={this.saveFile.bind(this)}
                                                    />
                                                    <button style={{ margin: "0" }} onClick={this.uploadFile.bind(this) } class="saveChangBtn">Upload/Change image</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Name of organization</td>
                                                <td colspan="2"><input id="inp_cl_name" onChange={(e) => { let cstat = this.state.clinicResults; cstat.name = e.target.value; this.setState({ clinicResults: cstat }) }} value={this.state.clinicResults ? this.state.clinicResults.name : ""} type="text" /></td>
                                                <td style={{ textAlign: "right" }}><button onClick={(e) => { e.preventDefault(); $("#inp_cl_name").focus(); $("#inp_cl_name").select() }} class="saveChangBtn">Change</button></td>
                                            </tr>
                                            <tr>
                                                <td>Organisation's description</td>
                                                <td colspan="2"><textarea id="inp_cl_desc" onChange={(e) => { let cstat = this.state.clinicResults; cstat.description = e.target.value; this.setState({ clinicResults: cstat }) }} value={this.state.clinicResults?this.state.clinicResults.description:"" } type="text" /></td>
                                                <td style={{ textAlign: "right" }}><button onClick={(e) => { e.preventDefault(); $("#inp_cl_desc").focus(); $("#inp_cl_desc").select() }} class="saveChangBtn">Change</button></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <hr className="custionHR"></hr>
                                    <h4 className="h4servicesDoctor">Location</h4>

                                    <table class="tablePinformation" >
                                        <tbody>
                                            <tr>
                                                <td>Address: </td>
                                                <td><input id="inp_cl_addr" onChange={(e) => { let cstat = this.state.clinicResults; cstat.locationAddress = e.target.value; this.setState({ clinicResults: cstat }) }} value={this.state.clinicResults ? this.state.clinicResults.locationAddress : ""} type="text" /></td>
                                                <td style={{ textAlign: "right" }}><button onClick={(e) => { e.preventDefault(); $("#inp_cl_addr").focus(); $("#inp_cl_addr").select() }} class="saveChangBtn">Change</button></td>

                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td><textarea id="inp_cl_adrdesc" onChange={(e) => { let cstat = this.state.clinicResults; cstat.locationDescription = e.target.value; this.setState({ clinicResults: cstat }) }} value={this.state.clinicResults ? this.state.clinicResults.locationDescription : ""} type="text" /></td>
                                                <td style={{ textAlign: "right" }}><button onClick={(e) => { e.preventDefault(); $("#inp_cl_adrdesc").focus(); $("#inp_cl_adrdesc").select() }} class="saveChangBtn">Change</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <button onClick={this.handleSaveChanges} class="saveChangBtn">Save changes</button>
                                    <br></br>
                                    <br></br>
                                </div>
                            </td>
                        </tr>
                    </table>


                    


                </div>
                
            );
        } else {
            setTimeout(function () {
                window.location.href = "/"
            }, 2000);
            return (
                <div className="">
                </div>
            );
            
        }
       

    }
}

export default UserClinic;