import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './BestDoctors.css';

class BestDoctors extends React.Component {

    
    componentDidMount() {
        var carousel = $(".carousel_bd"),
            currdeg = 0;
        $(".item_bd").css({ "opacity": "0.1" });
        $(".a").css({ "opacity": "1", "width": "250", "height": "200" });
        $(".next").on("click", { d: "n" }, rotate);
        $(".prev").on("click", { d: "p" }, rotate);

        var items = ["a","b","c","d","e","f"]

        function rotate(e) {
            if (e.data.d == "n") {
                currdeg = currdeg - 60;
            }
            if (e.data.d == "p") {
                currdeg = currdeg + 60;
            }
            carousel.css({
                "-webkit-transform": "rotateY(" + currdeg + "deg)",
                "-moz-transform": "rotateY(" + currdeg + "deg)",
                "-o-transform": "rotateY(" + currdeg + "deg)",
                "transform": "rotateY(" + currdeg + "deg)"
            });
            var activNum = (currdeg*-1) / 60;
            while (activNum < 0) {
                activNum = 6 + activNum;
            }
            while (activNum > 5) {
                activNum = activNum-6;
            }
            $(".item_bd").css({ "opacity": "0.1" });
            $("." + items[activNum]).css({ "opacity": "1", "width": "250", "height": "200"});
        }

    }
    render() {

        return (
            <div className='bestDoctorsHolder'>
                <div class="container_bd ">
                    <div class="carousel_bd ">
                        <div class="item_bd  a">
                            <div className="individualDoctor_bd">
                                <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000000.png'} />
                            </div>

                        </div>
                        <div class="item_bd  b">
                            <div className="individualDoctor_bd">
                                <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000001.png'} />
                            </div>
                        </div>
                        <div class="item_bd  c">
                            <div className="individualDoctor_bd">
                                <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000002.png'} />
                            </div>
                        </div>
                        <div class="item_bd  d">
                            <div className="individualDoctor_bd">
                                <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000003.png'} />
                            </div>
                        </div>
                        <div class="item_bd  e">
                            <div className="individualDoctor_bd">
                                <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000004.png'} />
                            </div>
                        </div>
                        <div class="item_bd  f">
                            <div className="individualDoctor_bd">
                                <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000005.png'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="next"></div>
                <div class="prev"></div>
            </div>
        );

    }
}

export default BestDoctors;