import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './search.css';
import imgLupaSiva from './lupa_siva.png';
import imgLokacijaSiva from './lokacija_siva.png';
import imgKalendarSivi from './kalendar_sivi.png';
import imgLupaBela from './lupa_bela.png';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';


class Search extends React.Component {
    constructor(props) {

        super();
    }

    render() {
       
        return (
            <div>
                <div className='searchHolder'>
                    <table className='searchFormHolder' >
                        <tr >
                            <td className='searchForm_looking'><img src={imgLupaSiva} alt="" /> <input id="input_searchKeyWord" type='text' placeholder='What are you looking for?' /></td>
                            <td className='searchForm_location'><img src={imgLokacijaSiva} alt="" /><input id="input_searchLocation" type='text' placeholder='Country, City' /></td>
                            <td className='searchForm_date'><img src={imgKalendarSivi} alt="" /><DateRangePicker disablePast placeholder="Exact Date, Date Range" /></td>
                            <td onClick={ this.props.actionClick } className='searchForm_search'><img src={imgLupaBela} alt="" /></td>
                        </tr>
                    </table>
                </div>
                <div className='searchHolderMob'>
                    <table className='searchFormHolderMob' >
                        <tr >
                            <td className='searchForm_looking'><img src={imgLupaSiva} alt="" /> <input id="input_searchKeyWord" type='text' placeholder='What are you looking for?' /></td>
                        </tr>
                        <tr>
                            <td className='searchForm_location'><img src={imgLokacijaSiva} alt="" /><input id="input_searchLocation" type='text' placeholder='Country, City' /></td>
                        </tr>
                        <tr>
                            <td className='searchForm_date'><img src={imgKalendarSivi} alt="" /><DateRangePicker disablePast showOneCalendar placeholder="Exact Date, Date Range" /></td>
                        </tr>
                        <tr>
                            <td onClick={this.props.actionClick} className='searchForm_searchMob'><img src={imgLupaBela} alt="" /> Search </td>
                        </tr>
                    </table>
                </div>
            </div>
        );

    }

}
export default Search;
