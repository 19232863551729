import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './SearchResultDoctor.css';

import SearchDoctorCard from '../SearchDoctorCard/SearchDoctorCard'

class SearchResultDoctor extends React.Component {
    render() {
        if (this.props.doctorList == null || this.props.doctorList.length == 0) {
            return (

                <div className="result_doctor result_hide">
                    <h4 className='h4_notFound'>Sorry we could not find any doctors matching your request.</h4>
                </div>
            );
        } else {
            return (

                <div className="result_doctor result_hide">

                    {this.props.doctorList.map((value, i) => {
                        return (
                            <SearchDoctorCard
                                key={"sdc_" + i}
                                doctor={value}
                                handleBookingClick={this.props.handleBookingClick}
                            />
                        )
                    })}


                </div>
            );
        }

    }
}
export default SearchResultDoctor;