import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import './carousel_19072020.css';

import carousel_img_1 from './carousel_1.jpg';
import carousel_img_2 from './carousel_1.jpg';
import carousel_img_3 from './carousel_1.jpg';

class Carousel extends React.Component {
  componentDidMount () {

    const track = document.querySelector('.carousel_track');
    const slides = Array.from(track.children);
    const nextButton = document.querySelector('.carousel_button--right');
    const prevButton = document.querySelector('.carousel_button--left');
    const dotsNav = document.querySelector('.carousel_nav');
    const dots = Array.from(dotsNav.children);

    const slideWidth = slides[0].getBoundingClientRect().width;
    const setSlidePosition = (slide, index) => {
      slide.style.left = slideWidth * index + 'px';
    };
    slides.forEach(setSlidePosition);

    const moveToSlide = (track, currentSlide, targetSlide) => {
      track.style.transform = 'translateX(-' + targetSlide.style.left + ')';
      currentSlide.classList.remove('current-slide');
      targetSlide.classList.add('current-slide');
    }
    const updateDots = (currentDot, targetDot)  => {
      currentDot.classList.remove('current-slide');
      targetDot.classList.add('current-slide');
    }

    const hideShowArrows = (slides, prevButton, nextButton, targetIndex ) => {
      if (targetIndex === 0) {
        prevButton.classList.add('is-hidden');
        nextButton.classList.remove('is-hidden');
      } else if (targetIndex === slides.length - 1) {
        prevButton.classList.remove('is-hidden');
        nextButton.classList.add('is-hidden');
      } else {
        prevButton.classList.remove('is-hidden');
        nextButton.classList.remove('is-hidden');
      }
    }

    prevButton.addEventListener('click', e => {
      const currentSlide = track.querySelector('.current-slide');
      const prevSlide = currentSlide.previousElementSibling;
      const currentDot = dotsNav.querySelector('.current-slide');
      const prevDot = currentDot.previousElementSibling;
      const prevIndex = slides.findIndex(slide => slide === prevSlide);

      moveToSlide(track, currentSlide, prevSlide);
      updateDots(currentDot, prevDot);
      hideShowArrows(slides, prevButton, nextButton, prevIndex);
    });

    nextButton.addEventListener('click', e => {
      const currentSlide = track.querySelector('.current-slide');
      const nextSlide = currentSlide.nextElementSibling;
      const currentDot = dotsNav.querySelector('.current-slide');
      const nextDot = currentDot.nextElementSibling;
      const nextIndex = slides.findIndex(slide => slide === nextSlide);

      moveToSlide(track, currentSlide, nextSlide);
      updateDots(currentDot, nextDot);
      hideShowArrows(slides, prevButton, nextButton, nextIndex);
    });

    dotsNav.addEventListener('click', e => {
      const targetDot = e.target.closest('button');

      if (!targetDot) return;

      const currentSlide = track.querySelector('.current-slide');
      const currentDot = dotsNav.querySelector('.current-slide');
      const targetIndex = dots.findIndex(dot => dot === targetDot);
      const targetSlide = slides[targetIndex];

      moveToSlide(track, currentSlide, targetSlide);
      updateDots(currentDot, targetDot);
      hideShowArrows (slides, prevButton, nextButton, targetIndex);
        clearInterval(INTERVAL_CAROUSEL);

    });
    var c_index = 1;
    var INTERVAL_CAROUSEL = setInterval(function(){
      c_index++;

      if(c_index>3){
        c_index=3;

      }
      $("#nav_carousel_"+c_index).click();
    },10000);
  }
  render() {

    return (
        <div className="carousel">
          <button className="carousel_button carousel_button--left">
            <img src="https://image.flaticon.com/icons/png/512/60/60775.png" alt=""/> is-hidden
          </button>
          <div className="carousel_track-container">
            <ul className="carousel_track">
              <li className="carousel_slide current-slide">
                        <img className="carousel_images" src={process.env.PUBLIC_URL + '/img/home_page/korasel_slika_1.jpg'} alt=""/>  </li>
              <li className="carousel_slide">
                        <img className="carousel_images" src={process.env.PUBLIC_URL + '/img/home_page/korasel_slika_2.jpg'} alt=""/></li>
              <li className="carousel_slide">
                        <img className="carousel_images" src={process.env.PUBLIC_URL + '/img/home_page/korasel_slika_3.jpg'} alt=""/></li>
            </ul>
          </div>

          <button className="carousel_button carousel_button--right">
            <img src="https://image.flaticon.com/icons/svg/60/60758.svg" alt=""/>
          </button>

          <div className="carousel_nav">
            <button id="nav_carousel_1" className="carousel_indicator current-slide"></button>
            <button id="nav_carousel_2" className="carousel_indicator"></button>
            <button id="nav_carousel_3" className="carousel_indicator"></button>
          </div>
        </div>
    );

  }
}

export default Carousel;
