import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './card.css';

class Card extends React.Component {

  componentDidMount(){


  }
  render() {

    return (
        <div  className ='cardHolderDiv'>
            <div className='cardHolderImg' style={{borderBottom: "0.3vh solid "+this.props.colorunder+""}}><img src ={this.props.imgtoshow}/></div>
            <div className='cardHolderTitle'>{this.props.tittletoshow}</div>
            <div className='cardHolderText'>{this.props.texttoshow}</div>
        </div>
    );

    }
}

export default Card;
