import React from 'react';
import ReactDOM from 'react-dom';

import './titleseparator_19072020.css';

class TitleSeparator extends React.Component {
  render() {

    return (
        <div className='ts_holder'>
           <h2>{this.props.text}</h2>
           <div className='h_line'></div>
        </div>
    );

  }
}

export default TitleSeparator;
