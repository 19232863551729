import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './signInForm.css';


class SignInForm extends React.Component {
    constructor(props) {

      super();
    }

    hideSingInForm() {
        $(".signInFormHolder").hide();
        $(".signInFormHolder_darker").hide();
    }
    loginUser() {
        let username = $("#username").val();
        let password = $("#password").val();
        this.props.loginUser(username,password);
    }
    render() {

        return (
            <div>
                <div onClick={this.hideSingInForm.bind(this)} className='signInFormHolder_darker'>

            </div>
            <div className='signInFormHolder'>
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>


                <br></br>
                <br></br>
                <h1>Sign-in Form</h1>
                <br></br>
                    <h3 id='singin_msg'> To access full content, please sign in. </h3> 
                <br></br>
                <br></br>
                <br></br>
                <label> Username/Email </label>
                <br></br>
                <br></br>
                <input id="username" placeholder="Please enter your username or email" type="text" />
                <br></br>
                <br></br>
                <label> Password </label>
                <br></br>
                    <br></br>
                    <input id="password" placeholder="Please enter your password" type="password"  />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                    <button onClick={this.loginUser.bind(this)} > Sign In </button>
                <br></br>
                <br></br>
                    <span> <a>Forgot you password?</a></span>
                <br></br>
                    <span> By signing in you agree with our <a>Terms & Conditions</a> and <a>Privacy Statement</a></span>
                <br></br>
                <br></br>
                <br></br>

                </div>
                </div>

        );

    }

}
export default SignInForm;
