import React from 'react';
import ReactDOM from 'react-dom';
import './homeInfoBoard_18072020.css';

import Carousel from './Carousel/Carousel'

class HomeInfoBoard extends React.Component {
  render() {

    return (
        <div className='hib_holder'>
          <div className="hib_left">
            <h1 className="hib_h1_wt">Welcome to</h1>
            <h1 className="hib_h1_hs">Health Scanner</h1>
            <div className="hib_ul_card">
              <ul>
                <li>Find doctors when you need them!</li>
                <li>Compare prices to find deals!</li>
                <li>Find the best professionals!</li>
              </ul>
            </div>
          </div>
          <div className="hib_right">
            <Carousel></Carousel>
          </div>
        </div>
    );

  }
}

export default HomeInfoBoard;
