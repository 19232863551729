import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './medicalTreatments.css';


import aesthetic_treatments from './slika 1.jpg';
import plastic_surgery from './slika 2.jpg';
import tooth_Whitening from './slika 3.jpg';



class MedicalTreatments extends React.Component {
    constructor(props) {
      super();
    }

    render() {

        return (
            <div className='medicalTreatmentsHolder'>
                <div className='mtCardHolder'>
                    <div className='mtCardHolderDiv'>
                        <div className='mtCardHolderImg' style={{ borderBottom: "0.3vh solid " + this.props.colorunder + "" }}><img src={aesthetic_treatments} /></div>
                        <div className='mtCardHolderTitle'>Aesthetic treatments</div>
                        <div className='mtCardHolderText'>
                            <span>&#8226;</span> Platelet-Rich Plasma (PRP)<br></br>
                            <span>&#8226;</span> PRP Skin Resurfacing<br></br>
                            <span>&#8226;</span> No-Downtime Laser Rejuvenation
                        </div>
                        <div className='mtCardHolderButton'><button>read more</button></div>
                    </div>
                    <div className='mtCardHolderDiv'>
                        <div className='mtCardHolderImg' style={{ borderBottom: "0.3vh solid " + this.props.colorunder + "" }}><img src={plastic_surgery} /></div>
                        <div className='mtCardHolderTitle'>Plastic surgery</div>
                        <div className='mtCardHolderText'>
                            <span>&#8226;</span> Breast augmentation or enlargement<br></br>
                            <span>&#8226;</span> Chin, cheek, or jaw reshaping<br></br>
                            <span>&#8226;</span> Facelift
                        </div>
                        <div className='mtCardHolderButton'><button>read more</button></div>
                    </div>
                    <div className='mtCardHolderDiv'>
                        <div className='mtCardHolderImg' style={{ borderBottom: "0.3vh solid " + this.props.colorunder + "" }}><img src={tooth_Whitening} /></div>
                        <div className='mtCardHolderTitle'>Dental treatments</div>
                        <div className='mtCardHolderText'>
                            <span>&#8226;</span> Tooth Whitening<br></br>
                            <span>&#8226;</span> Crowns<br></br>
                            <span>&#8226;</span> Dental implants
                        </div>
                        <div className='mtCardHolderButton'><button>read more</button></div>
                    </div>
                </div>
              <hr></hr>
            </div>
        );

    }
}

export default MedicalTreatments;
