import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';

import HomeInfoBoard from '../../components/HomeInfoBoard/HomeInfoBoard';
import TitleSeparator from '../../components/TitleSeparator/TitleSeparator';
import Search from '../../components/Search/Search';
import NeedToKnow from '../../components/NeedToKnow/NeedToKnow';
import MedicalTreatments from '../../components/MedicalTreatments/MedicalTreatments';
import OurBest from '../../components/OurBest/OurBest';
import './UserPage.css';

import { withRouter } from 'react-router';


class UserPage extends React.Component {
   
    constructor() {
        super();
        this.goToClinicPage.bind(this);
        this.cancelBooking.bind(this);
        this.saveFile.bind(this);
        this.uploadFile.bind(this);
        this.state = {
            cancelIndex: null,
            userResults: null,
            appointments: []
        }

        this.menuSelect.bind(this);
    }
    saveFile(e) {
        if (e) {
            console.log("FILEEEEE", e.target.files);
            this.setState({ file: e.target.files[0] });
            this.setState({ fileName: e.target.files[0].name });
        }

    }
    uploadFile() {
        console.log("STEFAN", this.state.file);
        if (this.state.file) {
            const formCollection = new FormData();
            formCollection.append("file", this.state.file)
            formCollection.append("fileName", this.state.fileName)

            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': 'Bearer ' + this.props.userInfo.token },
                body: formCollection
            };
            fetch('/api/User/UploadPicture/clinic_profile_imgs/test.jpg', requestOptions)
                .then(response => response.json())
                .then(data => {
                    alert("uploading done");
                })

        }

    }

    componentWillMount() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInfo.token },

        };
        fetch('/api/Booking/UserAppointmentList', requestOptions)
            .then(response => response.json())
            .then(data => {
                data.sort(function (a, b) {
                    return a.creationTime.localeCompare(b.creationTime);
                });
                data.reverse();
                this.setState({ appointments: data });
                console.log(data);
            })

        fetch('/api/User/GetAdminUser', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("OVde gledaj", data);
                this.setState({ userResults: data })


            });
    }
    handleSaveChanges = () => {
        var userInfo = this.state.userResults;
        console.log(userInfo);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + this.props.userInfo.token
            },
            body: JSON.stringify(userInfo)
        };
        fetch('/api/User/UpdateAdminUser', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("Update user", data);
                alert("users settings save done");
            })
    }
   
    goToClinicPage(id) {
        
        window.location.href = "/clinic/"+id;
    }
    cancelBooking(appid) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInfo.token },
            body: JSON.stringify({"id":appid})

        };
        fetch('/api/Booking/Cancellation', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                $("#info_cancelMessage").html(data.message);
                $(".missingInfo").show();
            })

        
    }

    menuSelect(option) {
        if (option == "appointments") {
            $(".useroptbtn").removeClass("selectedBtn");
            $("#app_btn").addClass("selectedBtn");
            $(".AppointmentsHolder").hide();
            $("#appointments").show();
        }
        if (option == "reviews") {
            $(".useroptbtn").removeClass("selectedBtn");
            $("#rev_btn").addClass("selectedBtn");
            $(".AppointmentsHolder").hide();
            $("#reviews").show();
        }
        if (option == "following") {
            $(".useroptbtn").removeClass("selectedBtn");
            $("#fol_btn").addClass("selectedBtn");
            $(".AppointmentsHolder").hide();
            $("#following").show();
        }
        if (option == "settings") {
            $(".useroptbtn").removeClass("selectedBtn");
            $("#set_btn").addClass("selectedBtn");
            $(".AppointmentsHolder").hide();
            $("#settings").show();
        }
    }
    
    render() {
        if (this.props.userLogedIn) {
            return (
                <div className="UserPageHolder">

                    <div className="missingInfo">
                        <h5 id="info_cancelMessage">Sorry you appointment cannot be canceled because its within 72h from now. </h5>
                        <button onClick={() => { $(".missingInfo").hide() }}> OK </button>
                    </div>
                    <div className="sucBooking">
                        <h5>Are you sure you want to cancel the appointment? </h5>
                        <button className='yesbtn' onClick={() => { this.props.cancelBooking(this.state.cancelIndex); $(".sucBooking").hide() }}> Yes </button>
                        <button onClick={() => { $(".sucBooking").hide() }}> No </button>
                    </div>

                    <table>
                        <tr>
                            <td>
                                <div className="UserMenu">
                                    <br></br>
                                    <br></br>
                                    <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000000.jpg'} />
                                    <h4>{this.props.userInfo.name}</h4>
                                    <button onClick={this.menuSelect.bind(this, 'appointments')} id="app_btn" className="useroptbtn selectedBtn">Appointments</button>
                                    <button onClick={this.menuSelect.bind(this, 'reviews')} id="rev_btn"className="useroptbtn">Reviews</button>
                                    {/*<button onClick={this.menuSelect.bind(this, 'following')} id="fol_btn" className="useroptbtn ">Following</button>*/}
                                    <button onClick={this.menuSelect.bind(this, 'settings')} id="set_btn" className="useroptbtn ">Settings</button>
                                    <div></div>
                                </div>
                            </td>

                            <td>
                                <div id="appointments" className="AppointmentsHolder">
                                    <h2>Your appointments :</h2>
                                    {
                                        this.state.appointments.map((value, i) => {
                                            let dateN = new Date(Date.now());
                                            let dateS = new Date(value.start);
                                            //alert(dateN + " ---" + dateS);
                                            if ((value.status == "CONFIRMED" || value.status == "PENDING")&& dateN<dateS)
                                                return (
                                                <div className="AppointmentCard" >

                                                    <h4>{value.start.split("T")[0] + "           " + value.start.split("T")[1]}</h4>
                                                    <br></br>
                                                    <h4>Address : {value.clinic.location.name}</h4>

                                                    <br></br>
                                                    <br></br>
                                                    <img src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/' + value.clinic.id + ".jpg"} className="clinicImg" />
                                                    <h4>Clinic : {value.clinic.name} </h4>
                                                    <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/' + value.doctor.id + ".png"} className="doctorImg" />
                                                    <h4>Doctor : {value.doctor.name} </h4>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <h4>Treatment : {value.threatmantDetail.name}  </h4>
                                                    <br></br>
                                                    <br></br>
                                                    <h4 style={{ color: "#1570B6" }}>Cancellation policy</h4><br></br>
                                                    <h4 style={{ color: "#1570B6" }}>72h hours befor the appointment</h4><br></br>
                                                    <p>
                                                        In case of not showing up for the appointment, or cancelling after the free cancellation period has ended, clients will be automatically charged the following amount: 50 Euros
                                                    </p>
                                                    <div className="Appoitment_btns">
                                                        <button onClick={() => this.goToClinicPage(value.clinic.id)} style={{ background: "#2D3E50", color: "white" }}>Go to the clinic page</button>
                                                        <button style={{ background: "#86C4C1", color: "white" }}>Contact clinic</button>
                                                        <button onClick={() => this.cancelBooking(value.id)} style={{ background: "grey", color: "white" }}>Cancel appointmet</button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div style={{display:"none"}} id="reviews" className="AppointmentsHolder">
                                    <h2>Your reviews :</h2>
                                    <div className="AppointmentCard" >
                                        <h4>You visited ASUMA dental clinic on - <span style={{ color: "#1D74B8" }}>Friday 15.01.2022</span></h4>
                                     
                                        <br></br>

                                        <br></br>
                                        <br></br>
                                        <img src={process.env.PUBLIC_URL + "/img/clinic_profile_imgs/1000000.jpg"} className="clinicImg" />
                                        <h4>Clinic : ASUMA dental </h4>
                                        <br></br>
                                        <br></br>
                                        <hr></hr>
                                        <p >
                                            Leave your Comment!
                                            <br></br><br></br>
                                            By doing so you help others decide whether the services offered by this clinic will suit them or not. Your opinion really counts!
                                        </p>
                                        <hr></hr>
                                   
                                        <br></br>
                                        <br></br>
                                        <h4 style={{ color: "#1D74B8" }}>Your comment!</h4>
                                        <textarea style={{width:"100%",height:"150px",background:"whitesmoke", resize:"none"}}>
                                            This is the most comfortable dentist office I have ever been to. The dentists (the Martin's) are absolutely fantastic professionals. The dental assistants are just as gifted. The front desk staff are highly experienced in the management of insurance issues, and are so friendly and helpful explaining everything. I highly doubt I will ever be as satisfied by another dental office.
                                        </textarea>
                                        <br></br>
                                        <p>
                                            Provide your rating (5-10)<br></br>
                                            <span style={{ color: "#86C4C1" }}>5</span> - being the minimal and <span style={{ color: "#86C4C1" }}>10</span> - being the highest score
                                        </p>
                                        <br></br>
                                        <input style={{
                                            width: "30px",
                                            textAlign:"center"
                                            }}
                                            type="text" value="7"/>
                                        <br></br>
                                        <button style={{float:"right", width: "125px", height: "35px", borderRadius:"5px", background: "#2D3E50", color: "white" }}>Save</button>
                                        <br></br>
                                        <br></br>
                                    </div>
                                    <div className="AppointmentCard" >
                                        <h4>You visited LENTRODT dental clinic on - <span style={{ color: "#1D74B8" }}>Friday 29.01.2022</span></h4>

                                        <br></br>

                                        <br></br>
                                        <br></br>
                                        <img src={process.env.PUBLIC_URL + "/img/clinic_profile_imgs/1000001.jpg"} className="clinicImg" />
                                        <h4>Clinic : LENTRODT dental </h4>
                                        <br></br>
                                        <br></br>
                                        <hr></hr>
                                        <p >
                                            Leave your Comment!
                                            <br></br><br></br>
                                            By doing so you help others decide whether the services offered by this clinic will suit them or not. Your opinion really counts!
                                        </p>
                                        <hr></hr>

                                        <br></br>
                                        <br></br>
                                        <h4 style={{ color: "#1D74B8" }}>Your comment!</h4>
                                        <textarea style={{ width: "100%", height: "150px", background: "whitesmoke", resize: "none" }}>
                                            Dr. Stephanie did my fillings today and I couldn’t be more pleased! With my last filling at a previous dentist I had issues not getting fully numbed, so Dr. Stephanie took her time and frequently checked in to make sure I couldn’t feel any pain. The whole process from start to finish made me feel calm in what would otherwise make me feel really anxious. This is a great practice :)
                                          </textarea>
                                        <br></br>
                                        <p>
                                            Provide your rating (5-10)<br></br>
                                            <span style={{ color: "#86C4C1" }}>5</span> - being the minimal and <span style={{ color: "#86C4C1" }}>10</span> - being the highest score
                                        </p>
                                        <br></br>
                                        <input style={{
                                            width: "30px",
                                            textAlign: "center"
                                        }}
                                            type="text" value="9" />
                                        <br></br>
                                        <button style={{ float: "right", width: "125px", height: "35px", borderRadius: "5px", background: "#2D3E50", color: "white" }}>Save</button>
                                        <br></br>
                                        <br></br>
                                    </div>
                                </div>
                                <div style={{ display: "none" }} id="following" className="AppointmentsHolder">
                                    
                                </div>
                                <div style={{ display: "none" }} id="settings" className="AppointmentsHolder">
                                    <h2>Personal information</h2>
                                    <table className="personalInfo">
                                        <tbody>
                                            <tr>
                                                <td colspan="3">
                                                    <img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000000.jpg'} />
                                                    <br></br>
                                                    <br></br>
                                                    <input
                                                        type="file"
                                                        id="profpic" name="profpic"
                                                        accept="image/jpeg"
                                                        onChange={this.saveFile.bind(this)}
                                                    />
                                                    <button style={{ margin: "0" }} onClick={this.uploadFile.bind(this)} class="saveChangBtn">Upload/Change image</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>First name</td>
                                                <td><input onChange={(e) => { let ustat = this.state.userResults; ustat.firstName = e.target.value; this.setState({ userResults: ustat }) }} value={this.state.userResults ? this.state.userResults.firstName : ""} type="text" /></td>
                                                <td><button class="saveChangBtn">Change</button></td>
                                            </tr>
                                            <tr>
                                                <td>Last name</td>
                                                <td><input onChange={(e) => { let ustat = this.state.userResults; ustat.lastName = e.target.value; this.setState({ userResults: ustat }) }} value={this.state.userResults ? this.state.userResults.lastName : ""} type="text" /></td>
                                                <td><button class="saveChangBtn">Change</button></td>
                                            </tr>
                                            <tr>
                                                <td>Your password</td>
                                                <td><input onChange={(e) => { let ustat = this.state.userResults; ustat.password = e.target.value; this.setState({ userResults: ustat }) }} value={this.state.userResults ? this.state.userResults.password : ""} type="password" placeholder="********" /></td>
                                                <td><button class="saveChangBtn">Change</button></td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td><input onChange={(e) => { let ustat = this.state.userResults; ustat.address = e.target.value; this.setState({ userResults: ustat }) }} value={this.state.userResults ? this.state.userResults.address : ""} type="text" /></td>
                                                <td><button class="saveChangBtn">Change</button></td>
                                            </tr>
                                            <tr>
                                                <td>Country</td>
                                                <td><input onChange={(e) => { let ustat = this.state.userResults; ustat.FirstName = e.target.value; this.setState({ userResults: ustat }) }} value="Germany" type="text" /></td>
                                                <td><button class="saveChangBtn">Change</button></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <hr className="custionHR"></hr>

                                    <h2 >Your pseudonym</h2>
                                    <h4 className="h4servicesDoctor">You have chosen the following pseudonym: <span>Belgradian</span></h4>
                                    <br></br>
                                    <textarea>By choosing your pseudonym you can leave your comment about a specific clinic you have visited or a
                                    doctor you have been treated by completely anonymously.
                                    Health related information is very private and we treat it very seriously. We have worked hard to make
sure that no one will ever have access to your health information.</textarea>

                                    <hr className="custionHR"></hr>

                                    <h2>Payment details</h2>
                                    <br></br>
                                    <table className="creditCard">
                                        <tbody>
                                            <tr>
                                                <td colspan="2">Credit card number</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><input className="inputLong" type="text" value="5462 9139 4244 5873" /></td>
                                            </tr>
                                            <tr>
                                                <td>Valid until</td>
                                                <td>CVV</td>
                                            </tr>
                                            <tr>
                                                <td><input className="inputShort" type="text" value="08/25" /></td>
                                                <td><input className="inputShort" type="text" value="898"/></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Card holder</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><input className="inputLong" type="text" value="Samantha Moore" /></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="saveBtn"><button onClick={this.handleSaveChanges} class="saveChangBtn">Save changes</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <br></br>
                                </div>
                            </td>
                        </tr>
                    </table>





                </div>
            );
        } else {
            setTimeout(function () {
                window.location.href = "/"
            }, 2000);
            return (
                <div className="">
                </div>
            );
            
        }
       

    }
}

export default UserPage;