import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import HomeInfoBoard from '../../components/HomeInfoBoard/HomeInfoBoard';
import TitleSeparator from '../../components/TitleSeparator/TitleSeparator';
import Search from '../../components/Search/Search';
import NeedToKnow from '../../components/NeedToKnow/NeedToKnow';
import MedicalTreatments from '../../components/MedicalTreatments/MedicalTreatments';
import OurBest from '../../components/OurBest/OurBest';

import BestDoctors from '../../components/BestDoctors/BestDoctors';

class Home extends React.Component {
    constructor() {
        super();
        this.goToSearchPage.bind(this);
    }

    componentDidMount(){
        $(document).on("keypress", "input", function (e) {
            if (e.which == 13) {
                var val = $("#input_searchKeyWord").val() || " ";
                var loc = $("#input_searchLocation").val() || " ";
                var date = $(".rs-picker-toggle-value").html() || "";

                var location = "/search/" + val + "/" + loc + "/" + date;

                window.location.href = location; } });
    }

    goToSearchPage() {
        var val = $("#input_searchKeyWord").val() || " ";
        var loc = $("#input_searchLocation").val() || " ";
        var date = $(".rs-picker-toggle-value").html() || "";

        var location = "/search/" + val + "/" + loc + "/" + date;
   
        window.location.href = location;

        
    }
    render() {

        return (
            <div>
                <HomeInfoBoard></HomeInfoBoard>
                <TitleSeparator text="Your search"></TitleSeparator>
                <Search actionClick={this.goToSearchPage.bind(this)} ></Search>
                <TitleSeparator text="How it works?"></TitleSeparator>
                <NeedToKnow></NeedToKnow>
                <TitleSeparator text="Most Sought-after Medical Treatments"></TitleSeparator>
                <MedicalTreatments></MedicalTreatments>
                <TitleSeparator text="Most Popular Doctors"></TitleSeparator>
                <BestDoctors/>
                <OurBest></OurBest>
            </div>
        );

    }
}

export default Home;