import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './SearchResultTreatment.css';

import SearchTreatmentCard from '../SearchTreatmentCard/SearchTreatmentCard'

class SearchResultTreatment extends React.Component {
    render() {
        if (this.props.treatmentList == null || this.props.treatmentList.length == 0) {
            return (

                <div className="result_treatment result_hide">
                    <h4 className='h4_notFound'>Sorry we could not find any treatments matching your request.</h4>
                </div>
            );
        } else {
            return (

                <div className="result_treatment result_hide">

                    {this.props.treatmentList.map((value, i) => {
                        return (
                            <SearchTreatmentCard
                                key={"stc_"+i}
                                treatment={value}
                                handleBookingClick={this.props.handleBookingClick}

                            />
                        )
                    })}


                </div>
            );
        }

    }
}
export default SearchResultTreatment;