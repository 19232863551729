import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './SearchDoctorCard.css';
import zeleniKrst from './zeleni_krst.png';
import eng22 from './english_icon22.png';
import ger from './german_icon.png';

class SearchDoctorCard extends React.Component {
    render() {

        return (

            <div className="search_doctorCard">
                <div className="line"></div>
                <div className="card">
                    <div className="search_imgCardDoctor" ><img src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/' + this.props.doctor.id + '.png'} /><div></div></div>
                    <div className="search_resultInfoHolderD">
                        <a href={process.env.PUBLIC_URL + "/doctor/" + this.props.doctor.id}><h4> {this.props.doctor.name}</h4></a>
                        <h5> {this.props.doctor.types}</h5>
                        <br></br>
                        <p id="style-1">{this.props.doctor.description}</p>
                        <div className="clinicName"><img src={zeleniKrst} /><a href={process.env.PUBLIC_URL + "/clinic/" + this.props.doctor.clinic.id}><h6>{this.props.doctor.clinic.name}</h6></a></div>
                        <div className="dentistLanguage"><span>Language/s spoken: </span><img src={eng22} /> <img src={ger} /></div>
                    </div>
                    <button onClick={() => this.props.handleBookingClick("doctor", this.props.doctor)}>Book a visit</button>
                </div>

                <div className="seach_doctorCardScoreLang"></div>
            </div>
        );

    }
}

export default SearchDoctorCard;