import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './BookingForm.css';

import img_lang from './german_icon.png';
import img_score from './score_icon.png'

class BookingForm extends React.Component {

    constructor() {
        super();
        this.state = {
            sDoctorIndex: null,
            sTreatmentIndex: null,
            sDateValue: null,
            sTimeValue: null
        }
        this.handleCancleBooking.bind(this);
        this.handleSelectDoctor.bind(this);
        this.handleDiselectDoctor.bind(this);
        this.setBookingTime.bind(this);
        this.finishBooking.bind(this);
    }
    componentDidMount() {

        if (this.props.bookingObject.selectedDoc != null) {
                this.setState({ sDoctorIndex: this.props.bookingObject.selectedDoc });
        }

    }
    handleCancleBooking() {
        this.setState({ sDoctorIndex: null });
        $(".BookingForm").hide();
    }
    handleDiselectDoctor() {
        this.setState({ sDoctorIndex: null });
        $(".BookingTimeSelect").addClass("unselectable");
        $(".btn_tim").addClass("unselectable");
        $(".finBookBtn").addClass("unselectable");

        $(".btn_tim").css({ "background": "white" });
    }
    handleSelectDoctor(index) {
        this.setState({ sDoctorIndex: index });
        $(".BookingTimeSelect").removeClass("unselectable");
    }
    setBookingDate() {
        this.setState({ sDateValue: $("#datesel").val()});
        $(".btn_tim").removeClass("unselectable");
    }
    setBookingTime(btnID) {
        var value = $("#" + btnID).html();

        this.setState({ sTimeValue: value });

        $(".btn_tim").css({ "background": "white" });
        $("#" + btnID).css({ "background": "rgb(93,179,176)" });
        $(".finBookBtn").removeClass("unselectable");
    }
    finishBooking() {

        if (this.props.userInfo.logedin) {
          
            let clinicId = this.props.bookingObject.clinicID;
            let doctorId = this.props.bookingObject.doctorList[this.state.sDoctorIndex].id;
            console.log(this.state.sTreatmentIndex);
            console.log(this.props.bookingObject.treatmentList);
            let threatmantId = this.props.bookingObject.treatmentList[this.state.sTreatmentIndex].id;
            let startingDate = this.state.sDateValue + "T" + this.state.sTimeValue.split("-")[0];
            let endingDate = this.state.sDateValue + "T" + this.state.sTimeValue.split("-")[1];

            const req_json = {
                clinicId: clinicId,
                doctorId: doctorId,
                threatmantId: threatmantId,
                startingDate: startingDate,
                endingDate: endingDate
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.props.userInfo.token },
                body: JSON.stringify(req_json),
                
            };
            console.log(req_json);
            fetch('/api/Booking/Reservation', requestOptions)
                .then(response => response.json())
                .then(data => {
                    //bc_treatmentName
                    //bc_treatmentPrice
                    //bc_clinicCity
                    //bc_clinicAddress
                    //bc_appointmentDate
                    //bc_appointmentTime
                    //bc_doctorPicture
                    //bc_doctorName
                    //bc_doctorType
                    //bc_clinicPicture
                    //bc_clinicName
                    //bc_clinicType
                    $("#bc_treatmentName").html(this.props.bookingObject.treatmentList[this.state.sTreatmentIndex].name);
                    $("#bc_treatmentPrice").html(this.props.bookingObject.treatmentList[this.state.sTreatmentIndex].price);
                    $("#bc_clinicCity").html(data.clinic.location.name.split(',')[1] + "," + data.clinic.location.name.split(',')[2]);
                    $("#bc_clinicAddress").html(data.clinic.location.name.split(',')[0]);
                    $("#bc_appointmentDate").html(this.state.sDateValue);
                    $("#bc_appointmentTime").html(this.state.sTimeValue);
                    $("#bc_doctorName").html(this.props.bookingObject.doctorList[this.state.sDoctorIndex].name);
                    $("#bc_doctorType").html(this.props.bookingObject.doctorList[this.state.sDoctorIndex].type);
                    $("#bc_clinicName").html(this.props.bookingObject.clinicName);
                    $("#bc_clinicType").html(this.props.bookingObject.clinicType);

                    //Tretman info
                    $("#bc_tDescription").html(data.threatmantDetail.group.description);
                    $("#bc_tPrecondition").html(data.threatmantDetail.group.precondition);
                    $("#bc_tDuration").html("Around "+data.threatmantDetail.group.duration+" minutes");
                    $("#bc_tRecovery").html(data.threatmantDetail.group.recovery);


                    //src = { process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000000.jpg' } />
                    $("#bc_doctorPicture").attr("src", process.env.PUBLIC_URL + '/img/doctor_profile_imgs/' + this.props.bookingObject.doctorList[this.state.sDoctorIndex].id + '.png');
                    $("#bc_clinicPicture").attr("src", process.env.PUBLIC_URL + '/img/clinic_profile_imgs/' + this.props.bookingObject.clinicID + '.jpg');
                    $("#bc_reservation").val(data.id);                                                                                                  //this.props.bookingObject.clinicID
                    $(".BookingConfirmation").show();
                    console.log(data);
                })

            
        } else {
            $(".signInFormHolder").show();
            $(".signInFormHolder_darker").show();
            $("#singin_msg").html("To request a booking you have to be signed in.");
            $("#singin_msg").css({ "color": "#f0bd24" });
        }


        
    }
    returnToBooking() {
        $(".BookingFormHolder").show();
        $(".missingInfo h5").html("You have not selected all items")
        $(".missingInfo").hide();
    }
    updateSelectedThreatment(){
        $(".BookingDoctorSelect").removeClass("unselectable");
        this.handleDiselectDoctor();
        this.setState({ sTreatmentIndex: $("#streat").val() });
    }
    render() {

        if (this.props.bookingType == "clinic") {
            return (
                <div className='BookingForm'>
                    <div className="missingInfo">
                        <h5>You have not selected </h5>
                        <button onClick={ ()=> this.returnToBooking() }> OK </button>
                    </div>

                    <div className="compBooking">


                    </div>

                    <div className="sucBooking">
                        <h5>You have successfully booked a treatment. For more information go to your account page.  </h5>
                        <button onClick={() => { $(".BookingForm").hide(); }}> OK </button>
                    </div>
                    <div onClick={()=>this.handleCancleBooking(this)} className='darker'></div>
                    <div className='BookingFormHolder'>
                        <img className="clinicLangImg" src={img_lang} />
                        <img className="clinicScoreImg" src={img_score} />
                        <button className="bkFormClose" onClick={() => { $(".BookingForm").hide(); }} >X</button>
                        <div className="BookingClincSelect">

                            <h3>{this.props.bookingObject.clinicType}</h3>
                            <h4>{this.props.bookingObject.clinicName}</h4>
                        </div>
                        <div className="BookingTreatmantSelect">
                            <select onChange={ this.updateSelectedThreatment.bind(this) } id="streat" name="treatments">
                                <option value="0" disabled selected>Select a treatment</option>
                                {
                                    this.props.bookingObject.treatmentList.map((value, i) => {
                                        return (
                                            <option value={i}>{value.name + " (" + value.price +"€)"}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="BookingDoctorSelect unselectable">

                            {

                                this.props.bookingObject.doctorList.map((value, i) => {
                                    if (this.props.bookingObject.selectedDoc != null && i == 0) {
                                        var obj = this.props.bookingObject.doctorList[this.props.bookingObject.selectedDoc];
                                        
                                        return (
                                            <div className="doctorCardBookingSelected">
                                                <div className="picture">
                                                    <img height="70%" src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/' + obj.id + '.png'} />
                                                    <img style={{ float: "left", border: "none" }} height="35px" src={img_score} />
                                                    <br></br>
                                                    <span> {obj.name} </span>
                                                    <br></br>
                                                    <span className="spanTypeD"> {obj.types[0]} </span>

                                                </div>
                                                <div className="description style-1"> {value.description}</div>
                                            </div>
                                       )
                                    }
                                    if (this.state.sDoctorIndex == null && i < 3 && this.props.bookingObject.selectedDoc == null) {
                                        return (
                                            <div onClick={() => this.handleSelectDoctor(i)} className="doctorCardBooking">
                                                <img width="119.3px" height="119.3px" src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/' + value.id + '.png'} />
                                                <img style={{ float: "left", border: "none" }} height="35px" src={img_score} />

                                                <br></br>
                                                <span> {value.name} </span>
                                                <br></br>
                                                <span className="spanTypeD"> {value.types[0]} </span>
                                            </div>
                                        )
                                    } else if (this.state.sDoctorIndex == i) {
                                        return (
                                            <div className="doctorCardBookingSelected">
                                                <div className="picture">
                                                    <img height="70%" src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/' + value.id + '.png'} />
                                                    <img style={{ float: "left", border: "none" }} height="35px" src={img_score} />
                                                    <br></br>
                                                    <span> {value.name} </span>
                                                    <br></br>
                                                    <span className="spanTypeD"> {value.types[0]} </span>

                                                </div>
                                                <div className="description style-1"> {value.description}</div>
                                                <button onClick={() => this.handleDiselectDoctor(this)} className="btn_deselectDoctor">X</button>
                                            </div>
                                        )
                                    } 
                                   
                                })
                            }
                        </div>
                        <div className="BookingTimeSelect unselectable">
                            <h4>Select a date</h4>
                            <input onChange={this.setBookingDate.bind(this)} min={Date.now().toString()} id="datesel" type="date" />
                            <br></br>
                            <br></br>
                            <h4>Select preferred time</h4>

                            <h5>Morning</h5>
                            <br></br>
                            <button onClick={() => this.setBookingTime("tim1")} className="btn_tim unselectable" id="tim1">09:00-09:30</button>
                            <button onClick={() => this.setBookingTime("tim2")} className="btn_tim unselectable" id="tim2">09:30-10:00</button>
                            <button onClick={() => this.setBookingTime("tim3")} className="btn_tim unselectable" id="tim3">10:00-10:30</button>
                            <button onClick={() => this.setBookingTime("tim4")} className="btn_tim unselectable" id="tim4">10:30-11:00</button>
                            <button onClick={() => this.setBookingTime("tim5")} className="btn_tim unselectable" id="tim5">11:00-11:30</button>
                            <button onClick={() => this.setBookingTime("tim6")} className="btn_tim unselectable" id="tim6">11:30-12:00</button>
                            <br></br>
                            <br></br>

                            <h5>Afternoon</h5>
                            <br></br>
                            <button onClick={() => this.setBookingTime("tim7")} className="btn_tim unselectable" id="tim7">14:00-14:30</button>
                            <button onClick={() => this.setBookingTime("tim8")} className="btn_tim unselectable" id="tim8">14:30-15:00</button>
                            <button onClick={() => this.setBookingTime("tim9")} className="btn_tim unselectable" id="tim9">15:00-15:30</button>
                            <button onClick={() => this.setBookingTime("tim10")} className="btn_tim unselectable" id="tim10">15:30-16:00</button>
                            <br></br>
                            <br></br>

                            <h5>Evening</h5>
                            <br></br>
                            <button onClick={() => this.setBookingTime("tim11")} className="btn_tim unselectable" id="tim11">18:00-18:30</button>
                            <button onClick={() => this.setBookingTime("tim12")} className="btn_tim unselectable" id="tim12">18:30-19:00</button>
                            <button onClick={() => this.setBookingTime("tim13")} className="btn_tim unselectable" id="tim13">19:00-19:30</button>
                            <button onClick={() => this.setBookingTime("tim14")} className="btn_tim unselectable" id="tim14">19:30-20:00</button>
                            <button onClick={() => this.setBookingTime("tim15")} className="btn_tim unselectable" id="tim15">20:00-20:30</button>
                            <button onClick={() => this.setBookingTime("tim16")} className="btn_tim unselectable" id="tim16">20:30-21:00</button>
                            <br></br>
                            <br></br>
                            <button onClick={() => this.finishBooking()} className="finBookBtn unselectable"> Complete  booking </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                    <div></div>
                )
        }
        

    }
}

export default BookingForm;
