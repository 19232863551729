import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './ButtonSearchTab.css';

class ButtonSearchTab extends React.Component {
    render() {

        var backgroundColor = "#FFFFFF";
        var action = null;



        if (this.props.type == "clinic") {

            backgroundColor = "rgb(93,179,176)";
            action = function () {
                $(".result_hide").hide();
                $(".result_clinic").show();
            }
        }
        if (this.props.type == "doctor") {
            backgroundColor = "#8AB8DA";
            action = function () {
                $(".result_hide").hide();
                $(".result_doctor").show();
            }
        }
        if (this.props.type == "treatment") {
            backgroundColor = "rgb(240,240,240)";
            action = function () {
                $(".result_hide").hide();
                $(".result_treatment").show();
            }
        }
        return (
            <button
                className="btn_searchTab"
                style={{ background: backgroundColor }}
                onClick={action}
            >
                {this.props.text} ( {this.props.resultsNum} )
            </button>
        );

    }
}

export default ButtonSearchTab;