import React from 'react';
import ReactDOM from 'react-dom';


import './ClinicNameLine.css';

class ClinicNameLine extends React.Component {
    render() {
        if (this.props.clinic == null || this.props.clinic.length == 0) {
            return (

                <div >
                    <h4>Sorry we could not find any clinic matching your request.</h4>
                </div>
            );
        } else {

            return (
                <div className="nameLine">

                    <h2>{this.props.clinic.type}</h2>
                    <h2>{this.props.clinic.name}</h2>

                </div>

            );
        }
    }
}

export default ClinicNameLine;