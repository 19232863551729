import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

import "./DoctorPage.css"

import TitleSeparator from '../../components/TitleSeparator/TitleSeparator';

import BookingFormDoctor from "../../components/BookingFormDoctor/BookingFormDoctor"

import BookingConfirmation from "../../components/BookingConfirmation/BookingConfirmation"


class DoctorPage extends React.Component {
    constructor() {
        super();
        this.state = {
            clinicResults: {
                name: "Loading",
                types: ["Loading"],
                description: ""
            },
            bookingType: null,
            bookingObject: {
                clincName: null,
                clinicType: null,
                doctorList: {},
                treatmentList: [],
                selectedDoc: null
            },
            bookingObjectDoctor: {
                clincName: null,
                clinicType: null,
                doctorList: {},
                treatmentList: {},
                selectedDoc: null
            }
        }
    }
    componentWillMount() {

        $('body').hide();
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/api/doctor/' + this.props.match.params.id, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({ clinicResults: data }, () => {
                    console.log(this.state.clinicResults);
                    $('body').show();
                });


            });
    }
    startBooking () {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "bookingType": "HS_DOCTOR",
                "identityKey": this.props.match.params.id
            })
        };
        fetch('/api/Booking/', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("RECIVE DOCTOR JSON")
                console.log(data);

                let clinic = data.clinic;
                let doctors = data.doctors[0];
                let threatmans = data.threatmans;

                this.setState({
                    bookingObject: {
                        clinicID: clinic.id,
                        clinicName: clinic.name,
                        clinicAddress: "FALI MI ADRESA KLINIKE",
                        clinicType: clinic.type,
                        doctorList: doctors,
                        treatmentList: threatmans,
                        selectedDoc: null
                    }
                }, () => {
                    this.setState({ bookingType: "doctor" }, () => {

                        setTimeout(function () {
                            $(".BookingFormDoctor").show();
                        }, 800);
                    });

                });


            });
    }
    render() {
        console.log("NAME : " + this.state.clinicResults.name);

        return (
            <div className="doctorPageHolder">

                <BookingFormDoctor
                    bookingType={this.state.bookingType}
                    bookingObject={this.state.bookingObject}
                    doctor={this.state.bookingObject.doctorList}
                    userInfo={this.props.userInfo}
                />
                <BookingConfirmation
                    userInfo={this.props.userInfo}
                />
               

                <div className="doctorTitleName"><span>Dr. {this.state.clinicResults.name}</span></div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className="doctorPicAbout">
                    <img className="mainpicdocotr" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/' + this.props.match.params.id+'.png'} />
                    <div className="abbout">
                        <h2>Dr. {this.state.clinicResults.name} </h2>
                        <br></br>
                        <br></br>
                        <br></br>
                        <h3>{this.state.clinicResults.types[0]}</h3>

                        <br></br>
                        <br></br>
                        <br></br>

                        <span>
                            {this.state.clinicResults.description}
                        </span>
                      
                        <img className="doctorrank" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/ocena.png'} />

                        <br></br>

                        <div className="divLangholder">
                        <span>Language/s spoken: </span>
                        <img className="doctorlang" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/zastava_nemacka.png'} ></img>
                        <img className="doctorlang" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/english_icon.png'} ></img>
                        </div>
                        
                        <br></br>
                        <button onClick={this.startBooking.bind(this)} className="buttonBookAvisit">Book a visit</button>
                    </div>

                </div>

                <br></br>
                <br></br>
                <TitleSeparator text="Reviews" />
                <br></br>
                <br></br>
                <img className="commentIMG" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/komentar_levi.png'} ></img>
                <img className="commentIMG" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/komentar_srednji.png'} ></img>
                <img className="commentIMG" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/komentar_desni.png'} ></img>
                <TitleSeparator text="My articles" />
                <br></br>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/How_often_should_I_brush_my_teeth.jpg'} ></img><br></br><br></br><div className="artcText">How often should I<br></br> brush my teeth?</div></div>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/Which_tooth_paste_to_use.jpg'} ></img><br></br><br></br><div className="artcText">Which toothbrush<br></br> to use?</div></div>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/dental_braces_when_to_start.jpg'} ></img><br></br><br></br><div className="artcText">Dental braces:<br></br> when to start?</div></div>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/which_toothbrush_to_use.jpg'} ></img><br></br><br></br><div className="artcText">Which tooth<br></br> paste to use?</div></div>
                <br></br>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/Teeth_sensitivity.jpg'} ></img><br></br><br></br><div className="artcText">Teeth<br></br> sensitivity</div></div>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/Dental_crowns_what_are_they.jpg'} ></img><br></br><br></br><div className="artcText">Dental crowns:<br></br> what are they?</div></div>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/teeth_whitening.jpg'} ></img><br></br><br></br><div className="artcText">Teeth<br></br> whitening</div></div>
                <div className="articleImageHolder"><img className="articleImage" src={process.env.PUBLIC_URL + '/img/doctor_page_imgs/Are_dental_X_Rays_safe.jpg'} ></img><br></br><br></br> <div className="artcText">Are dental<br></br> X Rays safe?</div></div>
                


                <br></br>
                <br></br>

            </div>

        );

    }
}

export default DoctorPage;