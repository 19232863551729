import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import './navbar_17072020.css';
import img_logo from "./logo.png";
import img_user from "./user.png";
import img_x from "./x.png";
import img_logout from "./logout.png";
import profile_img from "./profil_slika.png";

class Navbar extends React.Component {
    constructor() {
        super();
        
    }
    componentDidMount() {
        if (this.props.userLogedIn) {
            $("#signInBtn").hide();
            $("#signOutBtn").show();
        }
    }
    goToUserPage() {
        if (this.props.userLogedIn) {
            if (this.props.userInfo.username.startsWith("user")) {
                window.location.href = "/user"
            } else {
                window.location.href = "/userClinic"
            }
         
        } else {
            $(".signInFormHolder").show();
            $(".signInFormHolder_darker").show();
            $("#singin_msg").html("To access the user page you have to sing in first.");
            $("#singin_msg").css({ "color": "#f0bd24" });
        }
    }
    goToHomePage() {
        window.location.href = "/"
    }
    closeNavigation() {
        $(".navbar_mob_holder").css({ "right": "-75%" })
        $(".background").hide();
    }
    openNavigation() {
        $(".navbar_mob_holder").css({ "right": "-10px" })
        $(".background").show();
    }
    showSingInFormSignIn(){
        $(".signInFormHolder").show();
        $(".signInFormHolder_darker").show();
        $("#singin_msg").html("Please enter your username or email and password");
        $("#singin_msg").css({ "color": "" });
    }
  render() {

      return (
        <div>
            <div className='navbar_holder'>
              <div className='navbar_top'>
                <div className='navbar_logo'>
                    <img onClick={this.goToHomePage.bind(this)} src={img_logo} alt="" />
                </div>
                <div className='navbar_buttons'>
                        <button className='btn_list'>List your business with us</button>
                        <button id="signInBtn" onClick={this.showSingInFormSignIn.bind(this)} className='btn_signIn'>Sign in</button>
                          <button id="signOutBtn" onClick={this.props.logOutUser.bind(this)} className='btn_signIn'>Sign out</button>
                        <button onClick={ this.goToUserPage.bind(this) } className='btn_user'>&nbsp;</button>
                </div>
              </div>
              <div className='navbar_bottom'>
                    <div className='navbar_buttons'>
                        <button onClick={this.goToHomePage.bind(this)} className='btn_menu'>Home</button>
                          <button className='btn_menu btn_menu2'>Medical treatments</button>
                          <button className='btn_menu btn_menu2'>Wellness & Spa</button>
                          <button className='btn_menu btn_menu2'>Telemedicine</button>
                          <button className='btn_menu btn_menu2'>Medical journal</button>
                          <button className='btn_menu btn_menu2'>About us</button>
                </div>
              </div>
              </div>
              <div onClick={() => this.closeNavigation(this)} className='background'></div>
            <div className='navbar_mob_info_holder'>
                <img src={img_logo} alt="" />
                <button onClick={this.openNavigation} >&#8801;</button>
                <button onClick={this.goToUserPage.bind(this)} className='btn_user'>&nbsp;</button>
            </div>
            <div className='navbar_mob_info_holder_empty'></div>
            <div className='navbar_mob_holder'>

                  <img src={profile_img} /><br/>

                <span className='navbar_mob_holder_username'>Your name</span><br /><br />

                <a className='navbar_mob_holder_list_business'> List your business with us </a><br /><br />

                  <a onClick={this.goToHomePage.bind(this)} className='navbar_mob_holder_menu'> HOME </a><br /><br />
                <a className='navbar_mob_holder_menu'> MEDICAL TREATMENT </a><br /><br />
                <a className='navbar_mob_holder_menu'> WELLNESS & SPA </a><br /><br />
                <a className='navbar_mob_holder_menu'> TELEMEDICINE </a><br /><br />
                <a className='navbar_mob_holder_menu'> MEDICAL JOURNAL </a><br /><br />
                <a className='navbar_mob_holder_menu'> ABOUT US </a><br /><br /><br />

                <button onClick={ this.closeNavigation }></button><br /><br />
                <div className='navbar_mob_holder_logout'>
                    <img src={img_logout}/>
                    <a className='navbar_mob_holder_logout_button'> Log in </a>
                </div><br /><br />
            </div>
        </div>

    );

  }
}

export default Navbar;
