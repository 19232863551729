import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './ourBest.css';


class OurBest extends React.Component {
    constructor(props) {

      super();
    }

    render() {

        return (
          <div className='ourBestHolder'>
           
            <hr></hr>
          </div>

        );

    }

}
export default OurBest;
