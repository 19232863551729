import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './BookingConfirmation.css';


import img_score from './score_icon.png'

class BookingConfirmation extends React.Component {


    handleCancleBooking() {
        this.setState({ sDoctorIndex: null });
        $(".BookingConfirmation").hide();

        $(".BookingConfirmationMessage").hide();

    }

    handleFinishBooking() {
        const req_json = {
            id: $("#bc_reservation").val(),
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInfo.token },
            body: JSON.stringify(req_json),

        };
        console.log(req_json);
        fetch('/api/Booking/Confirmation', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status == "Confirmed") {
                    $(".BookingFormHolder").hide();
                    $(".BookingConfirmation").hide();
                    $(".sucBooking").show();
                    
                }
            })
    }

    render() {

            return (
                <div className='BookingConfirmation'>
                    <div onClick={() => this.handleCancleBooking(this)} className='darker'></div>
                    <div className='BookingConfirmationHolder'>
                        <div className="BookingConfirmationDetails">
                            <h3>Booking details</h3>

                            <table>
                                <tr>
                                    <td>Booking tretments:</td>
                                    <td id="bc_treatmentName" className="bold">White fillings</td>
                                </tr>
                                <tr>
                                    <td>Treatment price:</td>
                                    <td id="bc_treatmentPrice" className="bold">150 e sign</td>
                                </tr>
                                <tr>
                                    <td>City, Country:</td>
                                    <td id="bc_clinicCity" className="bold">Munich, Germany</td>
                                </tr>
                                <tr>
                                    <td>Address:</td>
                                    <td id="bc_clinicAddress" className="bold">Sedanstr, 11</td>
                                </tr>
                            </table>
                        </div>
                        <div className="BookingConfirmationAppointment">
                            <table>
                                <tr>
                                    <td>Appointment date:</td>
                                    <td id="bc_appointmentDate" className="bold">14.05.2021.</td>
                                </tr>
                                <tr>
                                    <td>Appointment time:</td>
                                    <td id="bc_appointmentTime" className="bold">15.00 pm</td>
                                </tr>
                            </table>
                            <br/>
                            <div className="BookingConfirmationDoctorHolder">
                                <span>With doctor:</span><br />
                                <div className="BookingConfirmationDoctor">

                                    <br></br>
                                    <img id="bc_doctorPicture" width="119.3px" height="119.3px" src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/2000000.jpg'} />
                                    <img style={{ float: "left", border: "none" }} height="35px" src={img_score} />

                                    <br></br>
                                    <span id="bc_doctorName"> Who </span>
                                    <br></br>
                                    <span id="bc_doctorType" className="spanTypeD"> Dentist </span>
                                </div>
                            </div>
                            <div className="BookingConfirmationClinicHolder">
                                <span>With clinic:</span><br/>
                                <div className="BookingConfirmationClinic">

                                    <br></br>
                                    <img id="bc_clinicPicture" width="119.3px" height="119.3px" src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/1000000.jpg'} />
                                    <img style={{ float: "left", border: "none" }} height="35px" src={img_score} />

                                    <br></br>
                                    <span id="bc_clinicName"> Who </span>
                                    <br></br>
                                    <span id="bc_clinicType" className="spanTypeD"> Dentist </span>
                                </div>
                            </div>

                        </div>
                        <div className="BookingConfirmationRules" id="style-1">
                            <div className="CancelationPolicy">
                                <h4>Cancelation Policy</h4>
                                <div className="hours"><span>72h</span></div>
                                <div className="bold"><span>This appointmenet can be canceled free of charge 72 hours before the sceduled time.</span></div>
                            </div>
                            <br/>
                            <h4>Description</h4>
                            <p id="bc_tDescription">Dental bonding is a cosmetic procedure in which a tooth-colored composite resin material is applied
                            to your teeth to improve your smile. Bonding can improve the appearance of teeth that are chipped,
                            broken, cracked, stained or have spaces between them.</p>

                            <br />
                            <h4>Preconditions</h4>
                            <p id="bc_tPrecondition">For these interventions, there is nothing that the patient has to do before the procedure!</p>

                            <br />
                            <h4>Duration</h4>
                            <p id="bc_tDuration">The procedure of repairing a tooth varies depending on the materials used and the size of
                            the caries. Of course, if it is the case that more than one tooth needs to be repaired the
                            procedure will last longer and in extreme cases all the necessary work cannot be performed in one day</p>

                            <br />
                            <h4>Recovery</h4>
                            <p id="bc_tRecovery">We only suggest that patients do not eat anything after having their fillings for 2 hours.
                            This is especially important after having the anesthesia since they might bit themselves.</p>
                        </div>
                        <div className="BookingConfirmationButton">
                            <input id="bc_reservation" style={{ display: "none" }} />
                            <button onClick={this.handleFinishBooking.bind(this)} className="finBookBtn">Finish booking</button>
                        </div>
                    </div>
                </div>
            );


    }
}

export default BookingConfirmation;
