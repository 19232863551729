import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import GoogleMapReact from 'google-map-react';


import './ClinicLocation.css';

const AnyReactComponent = ({ text }) => <div className="mapMarker bounce"><div className="mapMarkerPulse">{text}</div></div>;

class ClinicLocation extends React.Component {
    static defaultProps = {
        center: {
            lat: 48,
            lng: 12
        },
        zoom: 11
    };
    render() {

        var lat = null;
        var lng = null;
        if (this.props.clinic != null) {
            lat = this.props.clinic.location.latitude;
            lng = this.props.clinic.location.longitude;
        }
        if (this.props.clinic == null || this.props.clinic.length == 0) {
            return (

                <div >
                    <h4>Sorry we could not find any clinic matching your request.</h4>
                </div>
            );
        } else {
            return (
                <div className="locationHolder">
                    <div className="locationInfoHolder">
                        <h4>{this.props.clinic.type}</h4>
                        <h3>{this.props.clinic.name}</h3>
                        <h5>{this.props.clinic.location.name}</h5><br></br>
                        <h6>{this.props.clinic.location.description}</h6>

                    </div>
                    <div className="mapHolder">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBtHeb2Z8sOQ2k6jZdBE_SKPdjQ3YUs5_E" }}
                            defaultCenter={{ lat, lng }}
                            defaultZoom={this.props.zoom}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            <AnyReactComponent
                                lat={lat}
                                lng={lng}
                                text=""

                            />
                        </GoogleMapReact>
                    </div>
                    <div className="mapBehind"></div>
                </div>
            
            );
        }
    }
}

export default ClinicLocation;