import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import './needToKnow.css';
import Card from '../Card/Card';

import doktori from './doktori.png';
import doktorPlavi from './doktor_plavi.png';
import lilaIkonica from './lila_ikonica.png';
import prstKlik from './prst-_klik.png';
import zeleniKrst from './zeleni_krst.png';
import zutaIkona from './zuta_ikona.png';
import { Carousel } from 'rsuite';





class NeedToKnow extends React.Component {
    constructor(props) {
      super();
    }

    render() {

        return (
            <div className='ntkHolder'>
                <div className='ntkCardHolder'>
                        <Card
                            imgtoshow={prstKlik}
                            colorunder="rgb(229,161,139)"
                            tittletoshow="EASE OF USE"
                            texttoshow="With just a few clicks, you can book a medical threatmen in numerous countries across the world"
                        ></Card>
                        <Card
                            imgtoshow={zeleniKrst}
                            colorunder="rgb(0,181,177)"
                            tittletoshow="Access"
                            texttoshow="Access the best medical clinics and the most affordable healtcare"
                        ></Card>
                        <Card
                            imgtoshow={doktorPlavi}
                            colorunder="rgb(22,113,182)"
                            tittletoshow="CHOOSE LANGUAGE"
                            texttoshow="Choose your preffered specialist who speaks your language"
                        ></Card>
                        <Card
                            imgtoshow={lilaIkonica}
                            colorunder="rgb(150,111,177)"
                        tittletoshow="EXPERIENCE OF OTHERS"
                        texttoshow="Make a wise choice based on the experience of others"
                        ></Card>
                        <Card
                            imgtoshow={zutaIkona}
                            colorunder="rgb(215,195,129)"
                            tittletoshow="DATA PROTECTION"
                            texttoshow="Your data is safe with us, we will never sell your private information to any third-party nor will we ever analyze the data of individual customers"
                        ></Card>
                </div>
                <div className='ntkCardHolderMob'>
                    <Carousel key={`$bottom.$bar`} placement="bottom" shape="bar" className="custom-slider">
                        <Card
                          imgtoshow = {prstKlik}
                            colorunder="rgb(229,161,139)"
                            tittletoshow="EASE OF USE"
                            texttoshow= "With just a few clicks, you can book a medical treatment in numerous countries across the world"
                        ></Card>
                        <Card
                          imgtoshow = {zeleniKrst}
                          colorunder = "rgb(0,181,177)"
                          tittletoshow = "Access"
                          texttoshow = "Access the best medical clinics and the most affordable healtcare"
                        ></Card>
                        <Card
                          imgtoshow = {doktorPlavi}
                            colorunder="rgb(22,113,182)"
                            tittletoshow="CHOOSE LANGUAGE"
                            texttoshow= "Choose your preferred specialist who speaks your own language"
                        ></Card>
                        <Card
                          imgtoshow = {lilaIkonica}
                          colorunder = "rgb(150,111,177)"
                          tittletoshow= "EXPERIENCE OF OTHERS"
                          texttoshow= "Select a clinic or a doctor based on the reviews of others that have already used the same service - Make a wise choice based on the experience of others"
                        ></Card>
                        <Card
                          imgtoshow = {zutaIkona}
                          colorunder = "rgb(215,195,129)"
                          tittletoshow = "Safe for you"
                          texttoshow = "Your data is safe with us, we will never sell your private information to any third-party nor will we ever analyze the data of individual customers"
                        ></Card>
                    </Carousel>
                </div>
              <div className='ntkBanner'>
                <img src={doktori}/>
                <div className='overImage'>
                        <div className='numberDiv'><h2>50</h2><span>Countries</span></div>
                        <div className='numberDiv'><h2>2,300</h2><span>Clinics</span></div>
                        <div className='numberDiv'><h2>6,000</h2><span>Professionals</span></div>
                        <div className='numberDiv'><h2>30,000</h2><span>Treatments</span></div>
                </div>
              </div>
            </div>
        );

    }
}

export default NeedToKnow;
