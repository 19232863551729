import React from 'react';
import ReactDOM from 'react-dom';


import './ClinicPriceChart.css';

class ClinicPriceChart extends React.Component {

    render() {
        if (this.props.clinic == null || this.props.clinic.length == 0) {
            return (

                <div >
                    <h4>Sorry we could not find any clinic matching your request.</h4>
                </div>
            );
        } else {
            //onClick={() => this.props.handleBookingClick("threatman", this.props.treatment)}
            return (
                <div className="clinicPriceChart style-1">
                    {
                        this.props.clinic.threatmantDetails.map((value, i) => {
                                return (
                                    <div className="individualTreatment">
                                        <h3 className="individualTreatmentName">{this.props.clinic.threatmantDetails[i].name}</h3>
                                        <h3 className="individualTreatmentPrice">{this.props.clinic.threatmantDetails[i].price}€</h3>
                                        <button onClick={() => this.props.handleBookingClick("threatman", this.props.clinic.threatmantDetails[i])}>Book a treatment</button>
                                    </div>
                                )
                        })
                    }
                </div>
            );
        }
    }
}

export default ClinicPriceChart;