import React, { Component } from 'react';
import $ from 'jquery';

import './MessageUserBox.css';

export class MessageUserBox extends Component {
    handleHideClick (){
        $(".messageBoxWarpper").hide();
    }
    render() {
        return (
            <div className="messageBoxWarpper">
                <div className="messageBoxWarpperDarker"></div>
                <div className="messageBoxWarpperForm">
                    <h2>Save succesfull</h2>
                    <br/><br/>
                    <p>You have succesfully saved new personal information.</p>
                    <br /><br />
                    <button onClick={this.handleHideClick.bind(this) }>OK</button>
                    <br /><br />
                </div>
            </div>
        );
    }
}

