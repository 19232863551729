import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

import img_info_score from './score_icon.png'
import img_german_flag from './german_flag_icon.png'

import './SearchClinicCard.css';

class SearchClinicCard extends React.Component {
    render() { 

        return (

            <div className="search_clinicCard">
                
                <div className="line"></div>
                <div className="card">
                    <div className="search_imgCard" > <a href={process.env.PUBLIC_URL + "/clinic/" + this.props.clinic.id}><img src={process.env.PUBLIC_URL + '/img/clinic_profile_imgs/' + this.props.clinic.id + '.jpg'}/></a></div>
                    <div className="search_resultInfoHolder">
                        <div className="search_scoreLangHolder">
                            <img src={img_german_flag} />
                            <img src={img_info_score} />
                        </div>
                        <h3> {this.props.clinic.type} </h3>
                        <a href={ process.env.PUBLIC_URL +"/clinic/"+ this.props.clinic.id }><h4> { this.props.clinic.name }</h4></a>
                        <br></br>
                        <h6> PRICE CHART SAMPLE </h6>
                        <div className="search_cardPriceChart " id="style-1">
                            {
                                this.props.clinic.threatmantDetails!=null?
                                    this.props.clinic.threatmantDetails.map((value, i) => {
                                        return (<div className="item"> <span> {value.name} </span> <span> {value.price}€ </span>  </div>)
                                    })
                                    : this.props.clinic.threatmantDetails = null

                            }
                        </div>

                    </div>
                    <button onClick={() => this.props.handleBookingClick("clinic", this.props.clinic)}>Book a visit</button>
                </div>
               
                <div className = "seach_clinicCardScoreLang"></div>
            </div>
        );

    }
}

export default SearchClinicCard;