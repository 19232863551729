import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';


import './SearchPage.css';
import Search from '../../components/Search/Search';

//Images
import img_searchDoctorsImg from "./searchDoctorsImg.png";
import ButtonSearchTab from "./components/ButtonSearchTab/ButtonSearchTab";
import SearchResultClinic from "./components/SearchResultClinic/SearchResultClinic";
import SearchResultDoctor from "./components/SearchResultDoctor/SearchResultDoctor";
import SearchResultTreatment from "./components/SearchResultTreatment/SearchResultTreatment";

import BookingForm from "../../components/BookingForm/BookingForm"
import BookingFormDoctor from "../../components/BookingFormDoctor/BookingFormDoctor"
import BookingFormTreatmant from "../../components/BookingFormTreatmant/BookingFormTreatmant"
import BookingConfirmation from "../../components/BookingConfirmation/BookingConfirmation"

class SearchPage extends React.Component {
    constructor() {
        super();
        this.state = {
            resultNumClinic: 0,
            resultNumDoctor: 0,
            resultNumTreatment: 0,
            clinicResults: null, 
            doctorResults: null,
            treatmentResults: null,

            bookingType: null,
            bookingObject: {
                clincName: null,
                clinicType:null,
                doctorList: {},
                treatmentList: [],
                selectedDoc: null
            },
            bookingObjectDoctor: {
                clincName: null,
                clinicType: null,
                doctorList: {},
                treatmentList: {},
                selectedDoc: null
            }
        };
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleBookingClick = this.handleBookingClick.bind(this);
    }
    componentDidMount() {

        console.log(this.props)

        //$(".BookingConfirmation").show();
        if (this.props.match) {
            $("#input_searchKeyWord").val(this.props.match.params.val);
            $("#input_searchLocation").val(this.props.match.params.loc);
            $(".rs-picker-toggle-placeholder").html(this.props.match.params.date);

            this.handleSearchClick();
        }
        
    }

    handleBookingClick(type, object) {
       
        //alert(type);
        this.setState({ bookingType: null }, () => { });
        if (type == "clinic") {
            console.log(object);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "bookingType": "HS_CLINIC",
                    "identityKey": object.id
                })
            };
            fetch('/api/Booking/', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("RECIVE JSON")
                    console.log(data);
                    let clinic = data.clinic;
                    let doctors = data.doctors;
                    let threatmans = data.threatmans;
                    

                    this.setState({
                        bookingObject: {
                            clinicID: clinic.id,
                            clinicName: clinic.name,
                            clinicAddress: "FALI MI ADRESA KLINIKE",
                            clinicType: clinic.type,
                            doctorList: doctors,
                            treatmentList: threatmans,
                            selectedDoc: null
                        }
                    }, () => {
                            this.setState({ bookingType: type }, () => {
                           
                                setTimeout(function () {
                                    $(".BookingForm").show();
                                }, 800);
                            });
                        
                    });


                });
        }
        if (type == "doctor") {
            console.log(object);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "bookingType": "HS_DOCTOR",
                    "identityKey": object.id
                })
            };
            fetch('/api/Booking/', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("RECIVE DOCTOR JSON")
                    console.log(data);

                    let clinic = data.clinic;
                    let doctors = data.doctors[0];
                    let threatmans = data.threatmans;

                    this.setState({
                        bookingObject: {
                            clinicID: clinic.id,
                            clinicName: clinic.name,
                            clinicAddress: "FALI MI ADRESA KLINIKE",
                            clinicType: clinic.type,
                            doctorList: doctors,
                            treatmentList: threatmans,
                            selectedDoc: null
                        }
                    }, () => {
                        this.setState({ bookingType: type }, () => {
                            
                            setTimeout(function () {
                                $(".BookingFormDoctor").show();
                            }, 800);
                        });

                    });


                });

        }
        if (type == "threatman") {
            
            console.log("IKEEE")
            console.log(object);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "bookingType": "HS_THREATMANTDETAIL",
                    "identityKey": object.id
                })
            };
            fetch('/api/Booking/', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("RECIVE DOCTOR JSON")
                    console.log(data);

                    let clinic = data.clinic;
                    let doctors = data.doctors;
                    let threatmans = data.threatmans[0];

                    this.setState({
                        bookingObject: {
                            clinicID: clinic.id,
                            clinicName: clinic.name,
                            clinicAddress: "FALI MI ADRESA KLINIKE",
                            clinicType: clinic.type,
                            doctorList: doctors,
                            treatmentList: threatmans,
                            selectedDoc: null
                        }
                    }, () => {
                            this.setState({ bookingType: type }, () => {
                               $(".BookingFormTreatmant").show();       
                        });

                    });


                });

        }
            /*    this.setState({
                    bookingObject: {
                        clinicID : object.id,
                        clinicName: object.name,
                        clinicAddress: object.location.name,
                        clinicType: object.type,
                        doctorList: object.doctors,
                        treatmentList: object.threatmantDetails,
                        selectedDoc : null
                    }
                }, () => {
                        $(".BookingForm").show();
                });
               
            }
            if (type == "doctor") {
                console.log(object);
               
                this.setState({
                    bookingObject: {
                        clincID: object.clinic.id,
                        clincName: object.clinic.name,
                        clincAddress: object.clinic.location.name,
                        clinicType: object.clinic.type,
                         
                        doctorList: object,
                        treatmentList: object.threatmantDetails,
                        selectedDoc: null
                    }
                }, () => {
                    
                    $(".BookingFormDoctor").show();
                });
               
            }
            */

    }

    handleSearchClick() {

        this.setState({
            resultNumClinic: 0,
            resultNumDoctor: 0,
            resultNumTreatment: 0
        })
        $(".result_hide").hide();
        $(".loadingBar").show();

        var keyWord = $("#input_searchKeyWord").val();
        var location = $("#input_searchLocation").val();

        var dateValue = $(".rs-picker-toggle-value").html();

        var startingDate = "";
        var endingDate = "";

        try{
            var dateValue_split = dateValue.split(" ");
            startingDate = dateValue_split[0];
            endingDate = dateValue_split[2];
        } catch(e){

        }
       


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                keyWord: keyWord,
                location: location,
                startingDate: startingDate,
                endingDate: endingDate
            })
        };
        fetch('/api/search', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data.clinics.length);
                this.setState({ resultNumClinic: data.clinics.length });
                this.setState({ resultNumDoctor: data.doctors.length });
                this.setState({ resultNumTreatment: data.threatmans.length });

                this.setState({ clinicResults: data.clinics });
                this.setState({ doctorResults: data.doctors });
                this.setState({ treatmentResults: data.threatmans });

               
                $(".loadingBar").hide();

                if (data.threatmans.length > 0) {
                    $(".result_hide").hide();
                    $(".result_treatment").show();
                }
                if (data.doctors.length > 0) {
                    $(".result_hide").hide();
                    $(".result_doctor").show();
                }
                if (data.clinics.length > 0) {
                    $(".result_hide").hide();
                    $(".result_clinic").show();
                }

            });
    }

    render() {

        return (
            
            <div className="searchPageHolder">
                <BookingForm
                    bookingType={this.state.bookingType}
                    bookingObject={this.state.bookingObject}
                    addNewBooking={this.props.addNewBooking}
                    userInfo={this.props.userInfo}
                />
                <BookingFormDoctor
                    bookingType={this.state.bookingType}
                    bookingObject={this.state.bookingObject}
                    doctor={this.state.bookingObject.doctorList}
                    userInfo={this.props.userInfo}
                />
                <BookingFormTreatmant
                    bookingType={this.state.bookingType}
                    bookingObject={this.state.bookingObject}
                    doctor={this.state.bookingObject.doctorList}
                    userInfo={this.props.userInfo}
                />
                <BookingConfirmation
                    userInfo={this.props.userInfo}
                />
                <div className="ntkBanner ntkBannerSP">
                    <img id="img_search_doctor" src={img_searchDoctorsImg} />
                    <div className="overImage" />
                    <h4>Find doctors when you need them!</h4>
                </div>
                
                <Search
                    actionClick={this.handleSearchClick}
                />
                <ButtonSearchTab
                    text='Clinics'
                    resultsNum={this.state.resultNumClinic}
                    type = 'clinic'
                />
                <ButtonSearchTab
                    text='Doctors'
                    resultsNum={this.state.resultNumDoctor}
                    type='doctor'
                />
                <ButtonSearchTab
                    text='Treatments'
                    resultsNum={this.state.resultNumTreatment}
                    type='treatment'
                />
                <br></br>
                <img className="loadingBar" src={process.env.PUBLIC_URL + '/img/loading.gif'} />
                <SearchResultClinic
                    clinicList={this.state.clinicResults}
                    handleBookingClick={this.handleBookingClick}
                />
                <SearchResultDoctor
                    doctorList={this.state.doctorResults}
                    handleBookingClick={this.handleBookingClick}
                />
                <SearchResultTreatment
                    treatmentList={this.state.treatmentResults}
                    handleBookingClick={this.handleBookingClick}
                />
                <br></br>
                <br></br>
                <br></br>
            </div>
        );

    }
}

export default SearchPage;