import React from 'react';
import ReactDOM from 'react-dom';


import './BookIndividualDoctor.css';

class BookIndividualDoctor extends React.Component {
    render() {
        if (this.props.clinic == null || this.props.clinic.length == 0) {
            return (

                <div >
                    <h4>Sorry we could not find any clinic matching your request.</h4>
                </div>
            );
        } else {
            
            return (
                <div className="bookDoctorsHolder">
                    <div className="doctors">
                        {
                            this.props.clinic.doctors.map((value, i) => {
                                if(i<3)
                                return (
                                    <div className="individualDoctor">
                                        <a href={process.env.PUBLIC_URL + "/doctor/" + this.props.clinic.doctors[i].id}><img src={process.env.PUBLIC_URL + '/img/doctor_profile_imgs/' + this.props.clinic.doctors[i].id + '.png'} /></a>
                                        <a href={process.env.PUBLIC_URL + "/doctor/" + this.props.clinic.doctors[i].id}><h3 className="individualDoctorName">{this.props.clinic.doctors[i].name}</h3></a>
                                        <h4 className="individualDoctorType">{this.props.clinic.doctors[i].types}</h4>

                                    </div>
                                    )
                            })
                        }
                        
                    </div>
                    <div className="ntkHolderC">
                        <div className='ntkBannerC'>
                            <div className='overImageC'>
                                <div className='numberDivC'><h2>1,976 </h2><span>Active since</span></div>
                                <div className='numberDivC'><h2>10,000 </h2><span>Patients</span></div>
                                <div className='numberDivC'><h2>5</h2><span>Employees</span></div>
                                <div className='numberDivC'><h2>2</h2><span>Experts</span></div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default BookIndividualDoctor;