import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';


import './ClinicPage.css';
import TitleSeparator from '../../components/TitleSeparator/TitleSeparator';
import ClinicNameLine from './components/ClinicNameLine/ClinicNameLine';
import AboutClinic from './components/AboutClinic/AboutClinic';
import BookIndividualDoctor from './components/BookIndividualDoctor/BookIndividualDoctor';
import ClinicPriceChart from './components/ClinicPriceChart/ClinicPriceChart';
import ClinicLocation from './components/ClinicLocation/ClinicLocation';
import BookingForm from "../../components/BookingForm/BookingForm";
import BookingFormTreatmant from "../../components/BookingFormTreatmant/BookingFormTreatmant";
import BookingConfirmation from '../../components/BookingConfirmation/BookingConfirmation';


class ClinicPage extends React.Component {
    constructor() {
        super();
        this.state = {
            clinicResult: null,

            resultNumClinic: 0,
            resultNumDoctor: 0,
            resultNumTreatment: 0,
            clinicResults: null,
            doctorResults: null,
            treatmentResults: null,

            bookingType: null,
            bookingObject: {
                clincName: null,
                clinicType: null,
                doctorList: {},
                treatmentList: [],
                selectedDoc: null
            },
            bookingObjectDoctor: {
                clincName: null,
                clinicType: null,
                doctorList: {},
                treatmentList: {},
                selectedDoc: null
            }
        }
        this.handleBookingClick = this.handleBookingClick.bind(this);
    }
    componentWillMount() {
        $('body').hide();
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/api/clinic/' + this.props.match.params.id, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({ clinicResults: data }, () => {

                    $('body').show();
                });


            });
    }
    handleBookingClick(type, object) {

        //alert(type);
        this.setState({ bookingType: null }, () => { });
        if (type == "clinic") {
            console.log(object);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "bookingType": "HS_CLINIC",
                    "identityKey": object.id
                })
            };
            fetch('/api/Booking/', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("RECIVE JSON")
                    console.log(data);
                    let clinic = data.clinic;
                    let doctors = data.doctors;
                    let threatmans = data.threatmans;


                    this.setState({
                        bookingObject: {
                            clinicID: clinic.id,
                            clinicName: clinic.name,
                            clinicAddress: "FALI MI ADRESA KLINIKE",
                            clinicType: clinic.type,
                            doctorList: doctors,
                            treatmentList: threatmans,
                            selectedDoc: null
                        }
                    }, () => {
                        this.setState({ bookingType: type }, () => {

                            setTimeout(function () {
                                $(".BookingForm").show();
                            }, 800);
                        });

                    });


                });
        }
        if (type == "doctor") {
            console.log(object);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "bookingType": "HS_DOCTOR",
                    "identityKey": object.id
                })
            };
            fetch('/api/Booking/', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("RECIVE DOCTOR JSON")
                    console.log(data);

                    let clinic = data.clinic;
                    let doctors = data.doctors[0];
                    let threatmans = data.threatmans;

                    this.setState({
                        bookingObject: {
                            clinicID: clinic.id,
                            clinicName: clinic.name,
                            clinicAddress: "FALI MI ADRESA KLINIKE",
                            clinicType: clinic.type,
                            doctorList: doctors,
                            treatmentList: threatmans,
                            selectedDoc: null
                        }
                    }, () => {
                        this.setState({ bookingType: type }, () => {

                            setTimeout(function () {
                                $(".BookingFormDoctor").show();
                            }, 800);
                        });

                    });


                });

        }
        if (type == "threatman") {

            console.log("IKEEE")
            console.log(object);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "bookingType": "HS_THREATMANTDETAIL",
                    "identityKey": object.id
                })
            };
            fetch('/api/Booking/', requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("RECIVE DOCTOR JSON")
                    console.log(data);

                    let clinic = data.clinic;
                    let doctors = data.doctors;
                    let threatmans = data.threatmans[0];

                    this.setState({
                        bookingObject: {
                            clinicID: clinic.id,
                            clinicName: clinic.name,
                            clinicAddress: "FALI MI ADRESA KLINIKE",
                            clinicType: clinic.type,
                            doctorList: doctors,
                            treatmentList: threatmans,
                            selectedDoc: null
                        }
                    }, () => {
                        this.setState({ bookingType: type }, () => {
                            $(".BookingFormTreatmant").show();
                        });

                    });


                });

        }

    }
    render() {
        

        return (
            <div>
                
                <ClinicNameLine clinic={this.state.clinicResults}/>
                <TitleSeparator></TitleSeparator>
                <AboutClinic
                    handleBookingClick={this.handleBookingClick}
                    clinic={this.state.clinicResults}
                />
                <TitleSeparator text="Get to know our professionals"></TitleSeparator>
                <BookIndividualDoctor clinic={this.state.clinicResults}/>
                <TitleSeparator text="Price chart"></TitleSeparator>
                <ClinicPriceChart
                    clinic={this.state.clinicResults}
                    handleBookingClick={this.handleBookingClick}
                />
                <TitleSeparator text="Location"></TitleSeparator>
                <ClinicLocation clinic={this.state.clinicResults} />
                <BookingForm
                    bookingType={this.state.bookingType}
                    bookingObject={this.state.bookingObject}
                    addNewBooking={this.props.addNewBooking}
                    userInfo={this.props.userInfo}
                />
                <BookingFormTreatmant
                    bookingType={this.state.bookingType}
                    bookingObject={this.state.bookingObject}
                    doctor={this.state.bookingObject.doctorList}
                    userInfo={this.props.userInfo}
                />
                <BookingConfirmation
                    userInfo={this.props.userInfo}
                />
            </div>

            );

    }
}

export default ClinicPage;